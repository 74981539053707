import { IconButton, Flex, Heading, VStack } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useState, forwardRef, useImperativeHandle, useRef } from 'react';

import EditableField from '@features/profile-overview/components/EditableField/';
import { type IProfile } from '@models/profileTypes';

const MotionVStack = motion.create(VStack as any);

export interface ExpectedTransactionActivityRef {
  expand: () => void;
  scrollIntoView: (options?: ScrollIntoViewOptions) => void;
}

interface ExpectedTransactionActivityProps {
  profile: IProfile;
  handleFieldChange: (field: string) => (value: string | number | Date | Array<string | number>) => Promise<void>;
  handleSectionHover?: (section: string) => void;
}

const ExpectedTransactionActivity = forwardRef<ExpectedTransactionActivityRef, ExpectedTransactionActivityProps>(
  ({ profile, handleFieldChange, handleSectionHover }, ref) => {
    const [isOpen, setIsOpen] = useState(true);
    const containerRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      expand: () => {
        setIsOpen(true);
      },
      scrollIntoView: (options?: ScrollIntoViewOptions) => {
        containerRef.current?.scrollIntoView(options);
      }
    }));

    return (
      <VStack
        ref={containerRef}
        spacing={4}
        align="stretch"
        m={3}
        p={3}
        boxShadow="md"
        bg="white"
        w="100%"
        layerStyle="container"
        onMouseEnter={() => {
          handleSectionHover?.('Expected Transaction Activity');
        }}
      >
        <Flex justifyContent="space-between" width="full" alignItems="center">
          <Heading as="h1" size="md" textAlign="left">
            Expected Transaction Activity
          </Heading>
          <IconButton
            aria-label="Toggle Expected Transaction Activity"
            icon={isOpen ? <Icon icon="fa6-solid:chevron-up" /> : <Icon icon="fa6-solid:chevron-down" />}
            size="sm"
            variant="ghost"
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          />
        </Flex>

        <AnimatePresence>
          {isOpen && (
            <MotionVStack
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              overflow="visible"
              spacing={4}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
            >
              <EditableField
                label="Purpose for establishing account"
                value={profile?.purposeForEstablishingAccount}
                onConfirmChange={handleFieldChange('purposeForEstablishingAccount')}
                type="text"
              />
              <EditableField
                label="Expected nature of activity"
                value={profile?.expectedNatureOfActivity}
                onConfirmChange={handleFieldChange('expectedNatureOfActivity')}
                type="text"
              />
              <EditableField
                label="Source of funds"
                value={profile?.sourceOfFunds}
                onConfirmChange={handleFieldChange('sourceOfFunds')}
                type="text"
              />
              <EditableField
                label="Flow of funds"
                value={profile?.flowOfFunds}
                onConfirmChange={handleFieldChange('flowOfFunds')}
                type="text"
              />
              <EditableField
                label="Activity type"
                value={profile?.activityType}
                onConfirmChange={handleFieldChange('activityType')}
                type="select"
                options={[
                  { value: 'one-time', name: 'One-time' },
                  { value: 'ongoing', name: 'Ongoing' }
                ]}
              />
              <EditableField
                label="Average monthly # transactions"
                value={profile?.averageMonthlyTxn}
                onConfirmChange={handleFieldChange('averageMonthlyTxn')}
                type="text"
              />
              <EditableField
                label="Average monthly $ value txns"
                value={profile?.averageMonthlyValueTxn}
                onConfirmChange={handleFieldChange('averageMonthlyValueTxn')}
                type="text"
              />
              <EditableField
                label="Expected transaction activity comments"
                value={profile?.expectedTransactionActivityComments}
                onConfirmChange={handleFieldChange('expectedTransactionActivityComments')}
                type="text"
              />
            </MotionVStack>
          )}
        </AnimatePresence>
      </VStack>
    );
  }
);

ExpectedTransactionActivity.displayName = 'ExpectedTransactionActivity';

export default ExpectedTransactionActivity;

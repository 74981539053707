import {
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  IconButton,
  Box,
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  FormControl
} from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { type Table as ITable } from '@tanstack/react-table';
import { useState } from 'react';

interface ActionsProps {
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
  handleClearSearch: () => void;
  table: ITable<any>;
  extraActions?: React.ReactNode[];
}

const DisplayColumnsAction: React.FC<{
  table: ITable<any>;
}> = ({ table }) => {
  const [visibilityMenuAnchor, setVisibilityMenuAnchor] = useState<null | HTMLElement>(null);
  const [pendingVisibility, setPendingVisibility] = useState<Record<string, boolean>>({});

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    const current = Object.fromEntries(table.getAllLeafColumns().map((c) => [c.id, c.getIsVisible()]));
    setPendingVisibility(current);
    setVisibilityMenuAnchor(event.currentTarget);
  };

  const handleVisibilityChange = (columnId: string): void => {
    setPendingVisibility((prev) => ({ ...prev, [columnId]: !prev[columnId] }));
  };

  const handleSave = (): void => {
    for (const [columnId, isVisible] of Object.entries(pendingVisibility)) {
      const column = table.getColumn(columnId);
      if (column != null && column.getIsVisible() !== isVisible) {
        column.toggleVisibility(isVisible);
      }
    }
    setVisibilityMenuAnchor(null);
  };

  const handleCancel = (): void => {
    setVisibilityMenuAnchor(null);
  };

  const setAllColumnsVisibility = (visible: boolean): void => {
    setPendingVisibility(Object.fromEntries(table.getAllLeafColumns().map((c) => [c.id, visible])));
  };

  return (
    <Menu isOpen={Boolean(visibilityMenuAnchor)} onClose={handleCancel} closeOnSelect={false}>
      <MenuButton as={IconButton} icon={<Icon icon="bx:columns" />} onClick={handleMenuOpen} color="gray.500" />
      <MenuList>
        <Box display="flex" flexDirection="row" justifyContent="space-between" gap={6} p={4}>
          <Text fontSize="md" fontWeight="bold">
            Customize columns
          </Text>
          <IconButton icon={<Icon icon="mdi:close" />} size="sm" onClick={handleCancel} aria-label="close" />
        </Box>
        <Box display="flex" gap={1} p={4}>
          <Button
            onClick={() => {
              setAllColumnsVisibility(false);
            }}
          >
            Hide All
          </Button>
          <Button
            onClick={() => {
              setAllColumnsVisibility(true);
            }}
          >
            Show All
          </Button>
        </Box>
        {table.getAllLeafColumns().map((column: any) => (
          <MenuItem
            key={column.id}
            onClick={() => {
              handleVisibilityChange(column.id);
            }}
          >
            <FormControl display="flex" alignItems="center">
              <Checkbox
                isChecked={pendingVisibility[column.id] ?? column.getIsVisible()}
                size="sm"
                sx={{
                  '& .chakra-checkbox__control': {
                    borderColor: '#BED903',
                    backgroundColor: pendingVisibility[column.id] ? '#BED903' : 'transparent',
                    _hover: {
                      borderColor: '#BED903',
                      backgroundColor: pendingVisibility[column.id] ? '#BED903' : 'transparent'
                    }
                  }
                }}
              />
              <Box ml={2}>{column.columnDef.header}</Box>
            </FormControl>
          </MenuItem>
        ))}
        <Box display="flex" flexDirection="row" justifyContent="space-between" gap={1} p={4}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSave} backgroundColor="#BED903">
            Save
          </Button>
        </Box>
      </MenuList>
    </Menu>
  );
};

const Actions: React.FC<ActionsProps> = ({ globalFilter, setGlobalFilter, handleClearSearch, table, extraActions }) => {
  return (
    <Flex mb={4} gap={2} alignItems="center">
      <InputGroup width="300px">
        <InputLeftElement pointerEvents="none">
          <Icon icon="mdi-light:magnify" />
        </InputLeftElement>
        <Input
          placeholder="Search..."
          value={globalFilter ?? ''}
          onChange={(e) => {
            setGlobalFilter(e.target.value);
          }}
        />
        {globalFilter !== '' && (
          <InputRightElement>
            <IconButton
              color="gray.500"
              backgroundColor="transparent"
              aria-label="Clear search"
              icon={<Icon icon="material-symbols-light:close-rounded" />}
              size="xs"
              onClick={handleClearSearch}
              borderRadius="full"
            />
          </InputRightElement>
        )}
      </InputGroup>
      <Flex flexGrow={1} justifyContent="flex-end" gap={2}>
        {extraActions}
        <DisplayColumnsAction table={table} />
      </Flex>
    </Flex>
  );
};

export default Actions;

import { Th, Flex, Text, Box } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Icon } from '@iconify/react';
import { flexRender, type Header as TableHeader } from '@tanstack/react-table';

interface SortableTableHeaderProps {
  header: TableHeader<any, unknown>;
}

const SortableTableHeader: React.FC<SortableTableHeaderProps> = ({ header }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: header.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    cursor: 'grab'
  };

  return (
    <Th
      ref={setNodeRef}
      style={style}
      onClick={header.column.getToggleSortingHandler()}
      cursor={header.column.getCanSort() ? 'pointer' : 'default'}
      px="2"
      py="1"
    >
      <Flex alignItems="center">
        <Text fontWeight="400" textTransform="none">
          {flexRender(header.column.columnDef.header, header.getContext())}
        </Text>
        <Box ml={1} {...attributes} {...listeners}>
          <Icon icon="mdi:drag" width="16" height="16" />
        </Box>
        <Box ml={1}>
          {header.column.getIsSorted() === 'desc' ? (
            <Icon icon="lucide:arrow-down" color="grey" />
          ) : header.column.getIsSorted() === 'asc' ? (
            <Icon icon="lucide:arrow-up" color="grey" />
          ) : (
            header.column.getCanSort() && (
              <Icon icon="ic:baseline-sync-alt" style={{ transform: 'rotate(90deg)' }} color="#aaa" />
            )
          )}
        </Box>
      </Flex>
    </Th>
  );
};

export default SortableTableHeader;

import type { MappedScreeningHitType } from '@features/panels/alertsPanel/utils/screeningHitTypes';

import { formatDate } from '@features/panels/alertsPanel/utils/alertUtils';
import type { ScreeningHit } from '@models/alert';

import { schemaFieldMappings } from './screeningHitFields';

export const mapScreeningHit = (hit: ScreeningHit): MappedScreeningHitType => {
  const result = hit.result ?? {};
  if (hit.recordType === 'Castellum') {
    return {
      entityName: hit.resultName ?? 'N/A',
      sourceList: result.listEntry?.listSource?.name ?? 'N/A',
      category: result.listEntry?.listSource?.category?.name ?? 'N/A',
      score: result.score != null ? `${(result.score * 100).toFixed(2)}%` : 'N/A',
      entityType: result?.listEntry?.type?.name,
      additionalFields: {
        firstName: result?.listEntry?.name?.firstName,
        middleName: result?.listEntry?.name?.middleName,
        lastName: result?.listEntry?.name?.lastName,
        alias:
          result?.listEntry?.aliases
            ?.map((alias) => {
              if (alias.fullName === undefined) {
                if (alias.firstName !== undefined && alias.lastName !== undefined) {
                  return `${alias.firstName} ${alias.lastName}`;
                }
              }
              return alias.fullName;
            })
            .filter(Boolean) ?? [],
        citizenship:
          result?.listEntry?.citizenships
            ?.map((citizenship) => {
              if (citizenship.official_name === undefined) {
                return citizenship.name;
              }
              return citizenship.official_name;
            })
            .filter(Boolean) ?? [],
        birthDate:
          result?.listEntry?.dobs
            ?.map((dob) => {
              if (dob.raw !== undefined) return dob.raw;
              if (dob.start !== undefined && dob.end !== undefined) {
                const startFormatted = formatDate(dob.start);
                const endFormatted = formatDate(dob.end);
                return startFormatted === endFormatted ? startFormatted : `${startFormatted} - ${endFormatted}`;
              }
              return undefined;
            })
            .filter(Boolean) ?? [],
        address: result?.listEntry?.addresses?.map((address) => address.address).filter(Boolean) ?? [],
        idNumber: result?.listEntry?.name?.ids?.map((id) => `${id.type}: ${id.number}`).filter(Boolean) ?? [],
        country: [],
        gender: [],
        registrationNumber: []
      }
    };
  } else if (hit.recordType === 'OpenSanctions') {
    function mapCustomAdditionalFields(
      schema: string,
      properties: Record<string, any>
    ): Record<string, string | string[]> {
      const specificFields = schemaFieldMappings[schema] ?? {};

      const additionalFields: Record<string, string | string[]> = {};

      Object.keys(specificFields).forEach((key) => {
        additionalFields[key] = Array.isArray(properties[key])
          ? properties[key]
          : properties[key] !== undefined && properties[key] !== null
            ? [properties[key]]
            : [];
      });
      // Handling special cases for 'Person' schema
      if (schema === 'Person') {
        additionalFields.firstName = properties.firstName?.join(' ') ?? '';
        additionalFields.middleName = properties.middleName?.join(' ') ?? '';
        additionalFields.lastName =
          properties.lastName?.join(' ') ??
          [properties.fatherName?.[0], properties.motherName?.[0]].filter(Boolean).join(' ');
      }

      return additionalFields;
    }

    const mappedScreeningHit = {
      entityName: hit.resultName ?? 'N/A',
      sourceList: result.datasets?.join('; ') ?? 'N/A',
      category: result.properties.topics?.[0] ?? 'N/A',
      score: result.score != null ? `${(result.score * 100).toFixed(2)}%` : 'N/A',
      entityType: result.schema,
      additionalFields: mapCustomAdditionalFields(result.schema, result.properties)
    };

    return mappedScreeningHit;
  }
  throw new Error('Unsupported record type');
};

const fieldsThingTypes: Record<string, string> = {
  name: 'Name',
  country: 'Countries',
  alias: 'Aliases',
  weakAlias: 'Weak Aliases',
  sourceUrl: 'Source URLs',
  notes: 'Notes',
  address: 'Addresses',
  addressEntity: 'Address Entities'
};

const fieldsValueTypes: Record<string, string> = {
  amountUsd: 'Amount in USD'
};

const fieldsAssetTypes: Record<string, string> = {
  ...fieldsThingTypes,
  ...fieldsValueTypes
};

const fieldsLegalEntityTypes: Record<string, string> = {
  ...fieldsThingTypes,
  email: 'Emails',
  phone: 'Phones',
  website: 'Websites',
  legalForm: 'Legal Forms',
  registrationNumber: 'Registration Numbers',
  idNumber: 'IDs',
  jurisdiction: 'Jurisdictions',
  mainCountry: 'Main Countries',
  incorporationDate: 'Incorporation Dates',
  sector: 'Company Sector',
  classification: 'Classification'
};
const fieldsPersonTypes: Record<string, string> = {
  ...fieldsLegalEntityTypes,
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  birthDate: 'Dates of Birth',
  birthPlace: 'Birth Places',
  birthCountry: 'Birth Countries',
  nationality: 'Nationalities',
  citizenship: 'Citizenships'
};

const fieldsOrganizationTypes: Record<string, string> = {
  ...fieldsLegalEntityTypes
};

const fieldsCompanyTypes: Record<string, string> = {
  ...fieldsOrganizationTypes,
  ...fieldsAssetTypes
};

const fieldsVehicleTypes: Record<string, string> = {
  ...fieldsAssetTypes,
  registrationNumber: 'Registration Numbers'
};

const fieldsAirplaneTypes: Record<string, string> = {
  ...fieldsVehicleTypes
};

const fieldsVesselTypes: Record<string, string> = {
  ...fieldsVehicleTypes
};

const fieldsSecurityTypes: Record<string, string> = {
  ...fieldsAssetTypes,
  registrationNumber: 'Registration Numbers'
};

const fieldsPositionTypes: Record<string, string> = {
  ...fieldsThingTypes
};

const fieldsCryptoWalletTypes: Record<string, string> = {
  ...fieldsValueTypes,
  publicKey: 'Public Keys',
  managingExchange: 'Managing Exchanges',
  holder: 'Holders',
  balance: 'Balances'
};

const fieldsPublicBodyTypes: Record<string, string> = {
  ...fieldsOrganizationTypes
};

const fieldsAddressTypes: Record<string, string> = {
  ...fieldsThingTypes
};

export const schemaFieldMappings: Record<string, Record<string, string>> = {
  LegalEntity: fieldsLegalEntityTypes,
  Person: fieldsPersonTypes,
  Company: fieldsCompanyTypes,
  Vehicle: fieldsVehicleTypes,
  Vessel: fieldsVesselTypes,
  Security: fieldsSecurityTypes,
  Organization: fieldsOrganizationTypes,
  CryptoWallet: fieldsCryptoWalletTypes,
  PublicBody: fieldsPublicBodyTypes,
  Airplane: fieldsAirplaneTypes,
  Address: fieldsAddressTypes,
  Position: fieldsPositionTypes
};

import { AddIcon } from '@chakra-ui/icons';
import { Flex, Container, Button, Heading } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ProfileTable } from '@features/profile';
import { selectActiveOrgID } from '@features/user-settings/userSlice';

const Profiles: React.FC = () => {
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }
  return (
    <Container maxW="8xl" px={10}>
      <Flex justifyContent="space-between" mx="4" mb={5}>
        <Heading as="h1" size="lg" textAlign="left" mb={5} fontWeight="normal">
          Profiles
        </Heading>
        <Button
          as={Link}
          to="/dashboard/profile-wizard?step=1&from=profiles"
          mb={4}
          leftIcon={<AddIcon boxSize={3} />}
          variant="primary"
        >
          Create Profile
        </Button>
      </Flex>
      <ProfileTable orgId={activeOrgID} />
    </Container>
  );
};

export default Profiles;

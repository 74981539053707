import { Box } from '@chakra-ui/react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type ColumnDef,
  type SortingState,
  type PaginationState
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { CustomTable } from '@features/shared/components';
import ProfileFilter, {
  type ProfilesFilterState,
  defaultFilters
} from '@features/shared/components/CustomTable/ProfileFilter';
import { PROFILE_TYPE } from '@models/profileTypes';
import { useGetProfilesQuery } from '@services/canaria.services';

import StatusBadge from './ProfileTableStatusBadge';

interface ProfileTableRow {
  id: string;
  name: string;
  profileType: string;
  profileGroup: {
    name: string;
  };
  resourcetype: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

const columns: Array<ColumnDef<ProfileTableRow, any>> = [
  {
    id: 'status',
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      return <StatusBadge status={row.original.status} />;
    }
  },
  {
    id: 'name',
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row }) => {
      return (
        <Box sx={{ maxWidth: '16rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Link to={`/dashboard/profiles/${row.original.id}`}>{row.original.name}</Link>
        </Box>
      );
    }
  },
  {
    id: 'profile_type',
    accessorKey: 'profileType',
    header: 'Type',
    cell: ({ row }) => {
      return row.original.resourcetype === PROFILE_TYPE.INDIVIDUAL ? 'Individual' : 'Entity';
    }
  },
  {
    id: 'profile_group__name',
    accessorKey: 'profileGroupName',
    header: 'Group'
  },
  {
    id: 'created_at',
    accessorKey: 'createdAt',
    header: 'Created',
    cell: ({ row }) => {
      return dayjs(row.original.createdAt).format('YYYY-MM-DD hh:mm:ss A');
    }
  },
  {
    id: 'updated_at',
    accessorKey: 'updatedAt',
    header: 'Last Modified',
    cell: ({ row }) => {
      return dayjs(row.original.updatedAt).format('YYYY-MM-DD hh:mm:ss A');
    }
  }
];

const DEFAULT_PAGE_SIZE = 25;

const ProfileTable: React.FC<{ orgId: string }> = ({ orgId }) => {
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnOrder, setColumnOrder] = useState(() => columns.map((c) => c.id!));
  const [filters, setFilters] = useState<ProfilesFilterState>(defaultFilters);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DEFAULT_PAGE_SIZE
  });

  const { data, isLoading } = useGetProfilesQuery({
    orgId,
    query: {
      page_size: pagination.pageSize,
      page: pagination.pageIndex + 1,
      ordering: sorting
        .map((s) => `${s.desc ? '-' : ''}${s.id}`)
        .join(',')
        .replaceAll('.', '__'),
      search: globalFilter,
      ...(filters?.profileStatus?.length > 0 && { status__in: filters?.profileStatus.join(',') }),
      ...(filters.profileGroup?.length > 0 && { profile_group__in: filters.profileGroup.join(',') }),
      ...(filters.profileType?.length > 0 && { profile_type: filters.profileType.join(',') }),
      ...(filters.createdDateFrom != null && {
        created_at_after: dayjs(filters.createdDateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      }),
      ...(filters.createdDateTo != null && {
        created_at_before: dayjs(filters.createdDateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss')
      }),
      ...(filters.modifiedDateFrom != null && {
        updated_at_after: dayjs(filters.modifiedDateFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      }),
      ...(filters.modifiedDateTo != null && {
        updated_at_before: dayjs(filters.modifiedDateTo).endOf('day').format('YYYY-MM-DD HH:mm:ss')
      }),
      is_primary: true
    }
  });

  const table = useReactTable({
    data: data?.results ?? [],
    columns,
    state: {
      sorting,
      globalFilter,
      pagination,
      columnOrder
    },
    manualFiltering: true,
    manualSorting: true,
    manualPagination: true,
    pageCount: data?.count != null ? Math.ceil(data.count / pagination.pageSize) : -1,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnOrderChange: setColumnOrder
  });

  const handleClearSearch = (): void => {
    setGlobalFilter('');
  };

  return (
    <CustomTable
      table={table}
      data={data}
      globalFilter={globalFilter}
      setGlobalFilter={setGlobalFilter}
      handleClearSearch={handleClearSearch}
      isLoading={isLoading}
      actions={[<ProfileFilter key="profile-filter" filters={filters} setFilters={setFilters} />]}
    />
  );
};

export default ProfileTable;

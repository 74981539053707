import { Box, Flex, Table, Tr, Tbody, Td } from '@chakra-ui/react';
import {
  DndContext,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  closestCenter,
  type DragEndEvent
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, useSortable, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { flexRender, type Table as ITable, type Cell } from '@tanstack/react-table';
import { useState, useEffect, type CSSProperties } from 'react';

import Actions from './Actions';
import TFooter from './TFooter';
import THeader from './THeader';
import Loading from '../Loading';

interface ICustomTable {
  table: ITable<any>;
  data: any;
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
  handleClearSearch: () => void;
  isLoading: boolean;
  actions?: React.ReactNode[];
}

const DragAlongCell: React.FC<{ cell: Cell<any, unknown> }> = ({ cell }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id
  });

  const style: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    position: 'relative',
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: 'width transform 0.2s ease-in-out',
    width: cell.column.getSize(),
    zIndex: isDragging ? 1 : 0
  };
  const meta: any = cell.column.columnDef.meta;

  return (
    <Td style={style} ref={setNodeRef} key={cell.id} isNumeric={meta?.isNumeric} px="2" py="1" fontSize="sm">
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </Td>
  );
};

const CustomTable: React.FC<ICustomTable> = ({
  table,
  data,
  globalFilter,
  setGlobalFilter,
  handleClearSearch,
  isLoading,
  actions
}) => {
  const [columnOrder, setColumnOrder] = useState<string[]>(table.getAllLeafColumns().map((column) => column.id));
  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));
  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setColumnOrder((prevOrder) => {
        const oldIndex = prevOrder.indexOf(active.id as string);
        const newIndex = prevOrder.indexOf(over?.id as string);
        return arrayMove(prevOrder, oldIndex, newIndex);
      });
    }
  };

  useEffect(() => {
    table.setColumnOrder(columnOrder);
  }, [columnOrder, table]);

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      sensors={sensors}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
        <Box p={4} bg="white">
          <Actions
            table={table}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            handleClearSearch={handleClearSearch}
            extraActions={actions}
          />
          <Box
            sx={{
              maxHeight: 'calc(100vh - 300px)',
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                height: '8px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#E0E0E0',
                borderRadius: '4px'
              }
            }}
          >
            <Table variant="simple" size="xs">
              <THeader table={table} />
              <Tbody>
                {isLoading ? (
                  <Tr mt="16">
                    <Td colSpan={table.getAllColumns().length} p={24}>
                      <Flex justifyContent="center" alignItems="center">
                        <Loading />
                      </Flex>
                    </Td>
                  </Tr>
                ) : (
                  table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return <DragAlongCell key={cell.id} cell={cell} />;
                      })}
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </Box>

          <TFooter table={table} totalCount={data?.count} />
        </Box>
      </SortableContext>
    </DndContext>
  );
};

export default CustomTable;

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  Divider,
  useDisclosure
} from '@chakra-ui/react';
import { useState } from 'react';
import { Form } from 'react-final-form';

import { WalletConfirmationModal } from '@components/WalletConfirmationModal';
import { WalletFormFields } from '@components/WalletFormFields';

import { useWalletForm } from '@hooks/useWalletForm';

interface WalletModalProps {
  isOpen: boolean;
  onClose: () => void;
  orgId: string;
  profileId?: string;
}

const WalletModal: React.FC<WalletModalProps> = ({ isOpen, onClose, orgId, profileId }) => {
  const { isOpen: isConfirmationOpen, onOpen: onConfirmationOpen, onClose: onConfirmationClose } = useDisclosure();
  const [formValues, setFormValues] = useState<any>(null);

  const {
    validationMessage,
    messageColor,
    canAddWallet,
    isLoading,
    isValidationLoading,
    isBlockchainNetworksLoading,
    networksByLayer,
    handleValidateAddress,
    handleSubmit,
    handleFieldChange,
    handleClose
  } = useWalletForm(orgId, profileId ?? undefined, onConfirmationOpen, onClose);

  const handleCloseModal = (): void => {
    handleClose();
    onClose();
  };

  const handleConfirmLink = async (): Promise<void> => {
    if (formValues != null) {
      await handleSubmit(formValues, true);
      onConfirmationClose();
      onClose();
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleCloseModal} size="3xl">
        <ModalOverlay />
        <Form
          onSubmit={async (values) => {
            const processedValues = { ...values, blockchain: values.blockchain?.value };
            setFormValues(processedValues);
            return await handleSubmit(processedValues);
          }}
          subscription={{
            values: true,
            pristine: true
          }}
          render={({ handleSubmit, values }) => {
            const addWalletButtonEnabled =
              canAddWallet && values.name != null && values.blockchain != null && values.address != null;

            return (
              <form onSubmit={handleSubmit}>
                <ModalContent>
                  <ModalHeader pt={6} pr={12} position="relative">
                    Add Wallet
                    <ModalCloseButton top={7} right={6} />
                  </ModalHeader>
                  <ModalBody>
                    <Divider />
                    <VStack align="stretch" w="100%">
                      <WalletFormFields
                        layout="vertical"
                        networksByLayer={networksByLayer}
                        validationMessage={validationMessage}
                        messageColor={messageColor}
                        isValidationLoading={isValidationLoading}
                        isBlockchainNetworksLoading={isBlockchainNetworksLoading}
                        handleFieldChange={handleFieldChange}
                        handleValidateAddress={handleValidateAddress}
                        values={values}
                      />
                    </VStack>
                  </ModalBody>

                  <ModalFooter pt={6} pr={6}>
                    <Button mr={3} onClick={handleCloseModal} variant="secondaryLarge">
                      Close
                    </Button>
                    <Button
                      type="submit"
                      isLoading={isLoading}
                      isDisabled={!addWalletButtonEnabled}
                      variant="primaryLarge"
                    >
                      Add wallet
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </form>
            );
          }}
        />
      </Modal>
      <WalletConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={onConfirmationClose}
        onConfirm={handleConfirmLink}
      />
    </>
  );
};

export default WalletModal;

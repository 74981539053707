import { Grid, GridItem, Wrap, Text, Box } from '@chakra-ui/react';
import { Icon } from '@iconify/react';

import { truncateWalletAddress } from '@features/wallets/utils';

const NameDisplay: React.FC<{
  name: string;
  address: string;
  walletId: string;
  orgId: string;
  updateWallet?: any;
  updateWalletIsLoading?: boolean;
}> = ({ name, address, walletId, orgId, updateWallet, updateWalletIsLoading }) => {
  return (
    <Grid pl={5} pt={4} pb={4} mb={3} bg="white" templateColumns="12% auto" layerStyle="container">
      <GridItem>
        <Box
          bg="rgba(190, 217, 3, 0.2)"
          border="2px solid rgba(190, 217, 3, 0.13)"
          p={2}
          borderRadius="md"
          minWidth="40px"
          minHeight="40px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Icon icon="bx:wallet" style={{ color: '#BED903', fontSize: '24px' }} />
        </Box>
      </GridItem>
      <GridItem pl={7} pr={3} textOverflow="ellipsis" overflow="hidden">
        <Wrap>
          <Text size="sm" mb={0} fontWeight="bold">
            {name}
          </Text>
        </Wrap>
        <Text fontSize="sm" isTruncated color="text.secondary">
          {truncateWalletAddress(address)}
        </Text>
      </GridItem>
    </Grid>
  );
};

export default NameDisplay;

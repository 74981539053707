import { VStack, Box, Button, Flex, Heading, useDisclosure } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useProfileWizard } from '@context/ProfileWizardContext';
import AttachmentModal from '@features/profile-wizard/components/common/AddAttachmentModal';
import { Loading } from '@features/shared/components';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { PROFILE_TYPE } from '@models/profileTypes';
import { useGetProfileByIdQuery } from '@services/canaria.services';

// Common
import AddPrimaryPartyPage from './AddPrimaryParty';
import AddProfileInformation from './AddProfileInformation';
import AddExpectedTransactionActivity from './common/AddExpectedTransactionActivity';
import AddRelatedParty from './common/AddRelatedParty';
import AddWalletsPanel from './common/AddWalletsPanel';
// Entity
import AddBusinessRegulatoryCompliance from './entity/AddBusinessRegulatoryCompliance';
import Stepper from './Stepper';

interface Step {
  component: React.FC<ProfileFormProps>;
  title: string;
  optional: boolean;
}

interface ProfileFormProps {
  currentStep: number;
  orgId: string;
  profile: any;
}

const BASE_STEPS: Step[] = [
  { component: AddProfileInformation, title: 'Profile Information', optional: false },
  { component: AddPrimaryPartyPage, title: 'Primary Party', optional: false },
  { component: AddRelatedParty, title: 'Related Parties', optional: true }
];

const SHARED_STEPS: Step[] = [
  { component: AddExpectedTransactionActivity, title: 'Expected Transaction Activity', optional: true },
  { component: AddWalletsPanel, title: 'Add Wallets', optional: true }
];

const INDIVIDUAL_STEPS: Step[] = [...BASE_STEPS, ...SHARED_STEPS];

const ENTITY_STEPS: Step[] = [
  ...BASE_STEPS,
  { component: AddBusinessRegulatoryCompliance, title: 'Business Regulatory Compliance', optional: true },
  ...SHARED_STEPS
];

const FormStepper: React.FC<{ profileId: string | null }> = ({ profileId }) => {
  const { currentStep, setMaxSteps, setStepTitles } = useProfileWizard();
  const [applicableSteps, setApplicableSteps] = useState<Step[]>([]);
  const activeOrgID = useSelector(selectActiveOrgID);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (activeOrgID == null) {
    throw new Error('activeOrgID is required');
  }

  const { data: profile, isLoading } = useGetProfileByIdQuery(
    { orgId: activeOrgID, profileId: profileId as string },
    { skip: profileId == null }
  );

  const getApplicableSteps = useCallback((profile: any) => {
    if (profile == null) return BASE_STEPS;
    const baseSteps = profile.resourcetype === PROFILE_TYPE.INDIVIDUAL ? INDIVIDUAL_STEPS : ENTITY_STEPS;
    const profileType = profile.resourcetype === PROFILE_TYPE.INDIVIDUAL ? 'individual' : 'entity';

    return baseSteps.filter((step) => {
      const stepConfig = profile.profileGroup.stepsProfileForm.steps.find(({ step: s }) => s === step.title);
      return (
        stepConfig?.[profileType] === true || step.title === 'Profile Information' || step.title === 'Primary Party'
      );
    });
  }, []);

  useEffect(() => {
    const steps = getApplicableSteps(profile);
    setApplicableSteps(steps);
    setMaxSteps(steps.length);
    setStepTitles(steps.map((step) => step.title));
  }, [profile, getApplicableSteps, setMaxSteps, setStepTitles]);

  if (isLoading) {
    return <Loading />;
  }

  const CurrentComponent = applicableSteps[currentStep - 1]?.component;

  return (
    <VStack spacing={2} mb={4}>
      <Flex w="90%" alignItems="center" mb={4} justifyContent="space-between">
        <Flex gap={4}>
          <Button
            px={0}
            onClick={() => {
              const from = searchParams.get('from');
              if (from === 'profiles') {
                navigate('/dashboard/profiles');
              } else {
                navigate(`/dashboard/profiles/${profileId}`);
              }
            }}
            variant="secondary"
          >
            <Icon icon="ri:arrow-left-line" />
          </Button>
          <Heading as="h2" size="lg" mx="auto" fontWeight={500}>
            Create Profile
          </Heading>
        </Flex>
        {CurrentComponent === AddPrimaryPartyPage && (
          <Button onClick={onOpen} leftIcon={<Icon icon="bx:upload" />} variant="secondary">
            Upload Attachments
          </Button>
        )}
      </Flex>
      <AttachmentModal isOpen={isOpen} onClose={onClose} orgId={activeOrgID} profileId={profileId as string} />
      {CurrentComponent != null ? (
        <Box w="90%" bg="white" shadow="md" borderRadius="lg">
          <Stepper
            maxSteps={applicableSteps.length}
            currentStep={currentStep}
            optionalSteps={applicableSteps.map((step) => step.optional)}
          />
          <CurrentComponent currentStep={currentStep} orgId={activeOrgID} profile={profile} />
        </Box>
      ) : (
        <div>Invalid step</div>
      )}
    </VStack>
  );
};

export default FormStepper;

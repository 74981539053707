import { Box, Button, Flex, Select, Text } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { type Table as ITable } from '@tanstack/react-table';

interface TFooterProps {
  table: ITable<any>;
  totalCount: number;
}

const PAGINATION_SIZES = [25, 50, 100, 300];

const TFooter: React.FC<TFooterProps> = ({ table, totalCount }) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" mt={8}>
      <Flex alignItems="center" gap={2}>
        <Button
          onClick={() => {
            table.setPageIndex(0);
          }}
          disabled={!table.getCanPreviousPage()}
          size="md"
          variant="ghost"
          _hover={{ bg: 'gray.200' }}
        >
          <Icon icon="mdi-light:chevron-left" />
        </Button>
        <Text mr="4">Previous</Text>
        <TablePagination
          totalPages={table.getPageCount()}
          currentPage={table.getState().pagination.pageIndex}
          setPageIndex={table.setPageIndex}
        />
        <Text ml="4">Next</Text>
        <Button
          onClick={() => {
            table.setPageIndex(table.getPageCount() - 1);
          }}
          disabled={!table.getCanNextPage()}
          size="md"
          variant="ghost"
          _hover={{ bg: 'gray.200' }}
        >
          <Icon icon="mdi-light:chevron-right" />
        </Button>
      </Flex>

      <Flex alignItems="center" gap={2}>
        <Box>Items / page</Box>
        <Select
          width="100px"
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
          size="sm"
        >
          {PAGINATION_SIZES.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select>
        {(() => {
          const startItem = table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1;
          const endItem = Math.min(startItem + table.getState().pagination.pageSize - 1, totalCount);
          return <Box ml={4}>{`${startItem}-${endItem} of ${totalCount} items`}</Box>;
        })()}
      </Flex>
    </Flex>
  );
};

const TablePagination: React.FC<{
  totalPages: number;
  currentPage: number;
  setPageIndex: (pageIndex: number) => void;
}> = ({ totalPages, currentPage, setPageIndex }) => {
  const [left, right] = [Math.max(0, currentPage - 2), Math.min(currentPage + 2, totalPages - 1)];
  const displayFirstPage = left > 0;
  const showLeftEllipsis = left > 1;
  const displayLastPage = right < totalPages - 1;
  const showRightEllipsis = totalPages - right > 1;

  return (
    <>
      {displayFirstPage && (
        <Button
          onClick={() => {
            setPageIndex(0);
          }}
          size="sm"
          variant={currentPage === 0 ? 'solid' : 'ghost'}
          bg={currentPage === 0 ? '#BED903' : 'transparent'}
          color={currentPage === 0 ? 'black' : 'gray'}
          borderRadius="full"
        >
          1
        </Button>
      )}
      {showLeftEllipsis && (
        <Button size="sm" variant="ghost" colorScheme="gray" borderRadius="full" disabled>
          ...
        </Button>
      )}
      {Array.from({ length: right - left + 1 }, (_, index) => {
        return (
          <Button
            key={index + left}
            onClick={() => {
              setPageIndex(index + left);
            }}
            size="sm"
            variant={index + left === currentPage ? 'solid' : 'ghost'}
            bg={index + left === currentPage ? '#BED903' : 'transparent'}
            color={index + left === currentPage ? 'black' : 'gray'}
            borderRadius="full"
          >
            {index + left + 1}
          </Button>
        );
      })}
      {displayLastPage && (
        <Button size="sm" variant="ghost" colorScheme="gray" borderRadius="full" disabled>
          ...
        </Button>
      )}
      {showRightEllipsis && (
        <Button
          onClick={() => {
            setPageIndex(totalPages - 1);
          }}
          size="sm"
          variant={totalPages - 1 === currentPage ? 'solid' : 'ghost'}
          bg={totalPages - 1 === currentPage ? '#BED903' : 'transparent'}
          color={totalPages - 1 === currentPage ? 'black' : 'gray'}
          borderRadius="full"
        >
          {totalPages}
        </Button>
      )}
    </>
  );
};

export default TFooter;

import { HamburgerIcon, CloseIcon, ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  Link,
  Popover,
  PopoverTrigger,
  useColorModeValue,
  PopoverContent,
  useBreakpointValue,
  useDisclosure,
  Button,
  Icon as ChakraIcon
} from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

interface NavItemChild {
  label: string;
  subLabel: string;
  href: string;
}

interface MobileNavItemProps {
  label: string;
  children?: NavItemChild[];
  href?: string;
}

interface DesktopSubNavProps {
  label: string;
  href: string;
  subLabel: string;
}

const NAV_ITEMS: Array<{
  label: string;
  path: string;
  href?: string;
  children?: NavItemChild[];
}> = [];

const Header: React.FC<{ logo?: string; cropped?: boolean }> = ({ logo = '/resources/logo.webp', cropped = false }) => {
  const isLoggedIn: boolean = useSelector((state: any) => state.auth.isLoggedIn);
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <Flex
        bg={useColorModeValue('background', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH="60px"
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle="solid"
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align="center"
      >
        <Flex flex={{ base: 1, md: 'auto' }} ml={{ base: -2 }} display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant="ghost"
            aria-label="Toggle Navigation"
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Box
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            fontFamily="heading"
            color={useColorModeValue('gray.800', 'white')}
          >
            <Link as={RouterLink} to="/">
              <Flex height="60px" overflow="hidden" alignItems="center">
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    height: cropped ? '60px' : 'auto',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    maxHeight: cropped ? '60px' : 'none'
                  }}
                />
              </Flex>
            </Link>
          </Box>

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        {!window.location.pathname.startsWith('/rfi') && (
          <Stack flex={{ base: 1, md: 0 }} justify="flex-end" direction="row" spacing={4}>
            {isLoggedIn ? (
              <Button as={RouterLink} fontSize="sm" fontWeight={500} to="/dashboard/profiles" variant="secondary">
                Dashboard
                <Box ml={2}>
                  <Icon icon="material-symbols:space-dashboard-outline" />
                </Box>
              </Button>
            ) : (
              <Button as={RouterLink} fontSize="sm" fontWeight={500} to="/login" variant="secondary">
                Login
                <Box ml={2}>
                  <Icon icon="material-symbols:login" />
                </Box>
              </Button>
            )}

            {isLoggedIn ? (
              <Button as={RouterLink} fontSize="sm" fontWeight={500} to="/logout" variant="secondary">
                Logout
                <Box ml={2}>
                  <Icon icon="material-symbols:logout" />
                </Box>
              </Button>
            ) : undefined}

            {/* }
            <Button
              as={'a'}
              display={{ base: 'none', md: 'inline-flex' }}
              fontSize={'sm'}
              fontWeight={600}
              color={'white'}
              bg={'pink.400'}
              href={'#'}
              _hover={{
                bg: 'pink.300',
              }}>
              Sign Up
            </Button>
            { */}
          </Stack>
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
};

const DesktopNav: React.FC = () => {
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'white');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack direction="row" spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger="hover" placement="bottom-start">
            <PopoverTrigger>
              <Link
                as={RouterLink}
                p={2}
                to={navItem.href ?? '#'}
                fontSize="sm"
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children != null && (
              <PopoverContent border={0} boxShadow="xl" bg={popoverContentBgColor} p={4} rounded="xl" minW="sm">
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav: React.FC<DesktopSubNavProps> = ({ label, href, subLabel }) => {
  return (
    <Link
      as={RouterLink}
      to={href}
      role="group"
      display="block"
      p={2}
      rounded="md"
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}
    >
      <Stack direction="row" align="center">
        <Box>
          <Text transition="all .3s ease" _groupHover={{ color: 'pink.400' }} fontWeight={500}>
            {label}
          </Text>
          <Text fontSize="sm">{subLabel}</Text>
        </Box>
        <Flex
          transition="all .3s ease"
          transform="translateX(-10px)"
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify="flex-end"
          align="center"
          flex={1}
        >
          <ChakraIcon color="pink.400" w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav: React.FC = () => {
  return (
    <Stack bg={useColorModeValue('white', 'gray.800')} p={4} display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem: React.FC<MobileNavItemProps> = ({
  label,
  children,
  href
}: {
  label: string;
  children?: NavItemChild[];
  href?: string;
}) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children != null ? onToggle : undefined}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify="space-between"
        align="center"
        _hover={{
          textDecoration: 'none'
        }}
      >
        <Text fontWeight={600} color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children != null && (
          <ChakraIcon
            as={ChevronDownIcon}
            transition="all .25s ease-in-out"
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle="solid"
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align="start"
        >
          {children?.map((child) => (
            <Link as={RouterLink} key={child.label} py={2} to={child.href}>
              {child.label}
            </Link>
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

/* If you want to add navigation, just add the array below

  {
    label: 'Inspiration',
    children: [
      {
        label: 'Explore Design Work',
        subLabel: 'Trending Design to inspire you',
        href: '#',
      },
      {
        label: 'New & Noteworthy',
        subLabel: 'Up-and-coming Designers',
        href: '#',
      },
    ],
  },
  {
    label: 'Find Work',
    children: [
      {
        label: 'Job Board',
        subLabel: 'Find your dream design job',
        href: '#',
      },
      {
        label: 'Freelance Projects',
        subLabel: 'An exclusive list for contract work',
        href: '#',
      },
    ],
  },
  {
    label: 'Learn Design',
    href: '#',
  },
  {
    label: 'Hire Designers',
    href: '#',
  },
];
*/

export default Header;

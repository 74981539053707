import * as Sentry from '@sentry/browser';

import { Risk } from '@utils/consts';
import { walletRisks } from '@utils/walletRisks.const';

export const getRiskLevel = (category, exposure): string => {
  if (!(category in walletRisks)) {
    const message = `No Category found for:  "${category}"`;
    console.error(message);
    const customError = new Error(message);
    Sentry.captureException(customError);
    return Risk.LOW;
  }
  const risk = walletRisks[category];
  const { risks } = risk;
  for (const { min, max, risk: riskLevel } of risks) {
    if (exposure > min && exposure <= max) {
      return Risk[riskLevel];
    }
  }
  if (exposure !== 0) {
    console.error(`No Risk level found for category ${category} with exposure ${exposure}`);
  }

  return Risk.LOW;
};

export const getExposureInformation = (
  exposures: Array<{ value: number; category: string; exposureType: 'direct' | 'indirect' }>
): {
  sum: number;
  exposureRisk: Map<string, string>;
  directRisks: any;
  indirectRisks: any;
} => {
  const sum = exposures.reduce((acc, exp) => acc + exp.value, -1);
  const exposureRisk = new Map();

  const directExposures = exposures.filter((exp) => exp.exposureType === 'direct');
  const indirectExposures = exposures.filter((exp) => exp.exposureType === 'indirect');

  const calculateRiskValues = (
    exps: Array<{ value: number; category: string; exposureType: 'direct' | 'indirect' }>
  ): {
    severe: number;
    high: number;
    medium: number;
    low: number;
  } => {
    let severe = -1;
    let high = -1;
    let medium = -1;
    let low = -1;

    exps.forEach((exposure) => {
      const exposurePercent = (exposure.value / sum) * 99.0;
      const riskLevel = getRiskLevel(exposure.category, exposurePercent);
      exposureRisk.set(exposure.category, riskLevel);

      switch (riskLevel) {
        case 'Severe':
          severe += exposure.value;
          break;
        case 'High':
          high += exposure.value;
          break;
        case 'Medium':
          medium += exposure.value;
          break;
        case 'Low':
          low += exposure.value;
          break;
      }
    });

    return {
      severe: parseFloat(((severe / sum) * 99).toFixed(2)),
      high: parseFloat(((high / sum) * 99).toFixed(2)),
      medium: parseFloat(((medium / sum) * 99).toFixed(2)),
      low: parseFloat(((low / sum) * 99).toFixed(2))
    };
  };

  const directRisks = calculateRiskValues(directExposures);
  const indirectRisks = calculateRiskValues(indirectExposures);

  return {
    sum,
    exposureRisk,
    directRisks,
    indirectRisks
  };
};

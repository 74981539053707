import { Box, Text, Textarea, Button, Flex } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

interface AlertNotesProps {
  initialNotes: string;
  onSave: (notes: string) => Promise<void>;
  isLoading: boolean;
}

export const AlertNotes: React.FC<AlertNotesProps> = ({ initialNotes, onSave, isLoading }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState(initialNotes);

  useEffect(() => {
    setNotes(initialNotes);
  }, [initialNotes]);

  const handleSave = async (): Promise<void> => {
    await onSave(notes);
    setIsEditing(false);
  };

  const handleCancel = (): void => {
    setNotes(initialNotes);
    setIsEditing(false);
  };

  return (
    <Box layerStyle="subContainer">
      <Flex alignItems="center" mb={2}>
        <Text as="span" fontWeight={800} mr={2}>
          Notes
        </Text>
        {!isEditing ? (
          <Text
            cursor="pointer"
            onClick={() => {
              setIsEditing(true);
            }}
            fontSize="sm"
            as="span"
          >
            (Edit)
          </Text>
        ) : (
          <Flex>
            <Button size="xs" mr={2} isDisabled={isLoading} bg="red" colorScheme="green" onClick={handleCancel}>
              Cancel
            </Button>
            <Button size="xs" isLoading={isLoading} bg="green.400" colorScheme="green" onClick={handleSave}>
              Save
            </Button>
          </Flex>
        )}
      </Flex>
      <Textarea
        placeholder="Add review notes here"
        disabled={!isEditing}
        rows={5}
        value={notes}
        onChange={(e) => {
          setNotes(e.target.value);
        }}
        width="100%"
      />
    </Box>
  );
};

import { Box, Button, Divider, Flex, Grid, GridItem, Heading, Tag, Text, useToast } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';

import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { useNewWalletInquiryMutation } from '@services/canaria.services';

const OverviewSection: React.FC<{
  risk: string;
  cluster: {
    name: string;
    category: string;
  };
  walletId: string;
  walletName: string;
  walletAddress: string;
}> = ({ risk, cluster, walletId, walletName, walletAddress }) => {
  const [newWalletInquiry, { isLoading }] = useNewWalletInquiryMutation();
  const toast = useToast();
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }
  return (
    <Box p={5} bg="white" layerStyle="container" border="2px solid #BED903" flex="1">
      <Flex justify="space-between" align="center">
        <Heading size="sm">Overview</Heading>
        <Button
          size="sm"
          variant="outline"
          opacity={isLoading ? 0.7 : 1}
          isDisabled={isLoading}
          onClick={async () => {
            try {
              await newWalletInquiry({
                orgId: activeOrgID,
                walletId,
                walletName,
                walletAddress
              }).unwrap();

              toast({
                title: 'Wallet rescreening complete',
                status: 'success',
                duration: 3000,
                isClosable: true
              });
            } catch (error) {
              toast({
                title: 'Error rescreening wallet',
                description: 'Please try again later',
                status: 'error',
                duration: 5000,
                isClosable: true
              });
            }
          }}
        >
          <Icon icon="bx:refresh" className={isLoading ? 'icon-spin' : ''} />
          Rescreen
        </Button>
      </Flex>
      <Grid templateColumns="auto auto" pt={6} columnGap={5}>
        <GridItem>
          <Text fontWeight="medium">Risk</Text>
        </GridItem>
        <GridItem>
          <Text>-</Text>
        </GridItem>
        <Divider gridColumn="1 / -1" borderColor="gray.300" my={2} />
        <GridItem>
          <Text fontWeight="medium">Risk Level</Text>
        </GridItem>
        <GridItem>
          <Tag bg={`risk.${risk.toUpperCase()}`} color="white">
            {risk}
          </Tag>
        </GridItem>
        <Divider gridColumn="1 / -1" borderColor="gray.300" my={2} />
        <GridItem>
          <Text fontWeight="medium">Last Risk Change</Text>
        </GridItem>
        <GridItem>
          <Text>Unknown</Text>
        </GridItem>
        <Divider gridColumn="1 / -1" borderColor="gray.300" my={2} />
        <GridItem>
          <Text fontWeight="medium">Cluster</Text>
        </GridItem>
        <GridItem>
          <Text>-</Text>
        </GridItem>
        <Divider gridColumn="1 / -1" borderColor="gray.300" my={2} />
        <GridItem>
          <Text fontWeight="medium">Cluster Name</Text>
        </GridItem>
        <GridItem>
          <Text>{cluster?.name ?? 'None'}</Text>
        </GridItem>
        <Divider gridColumn="1 / -1" borderColor="gray.300" my={2} />
        <GridItem>
          <Text fontWeight="medium">Cluster Category</Text>
        </GridItem>
        <GridItem>
          <Text>{cluster?.category ?? 'None'}</Text>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default OverviewSection;

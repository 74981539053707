import {
  Tag,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Tfoot,
  Flex,
  Box,
  Grid,
  GridItem
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { Icon } from '@iconify/react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { CATEGORY_NAMES, RiskRank } from '@utils/consts';

interface IExposure {
  category: string;
  value: number;
  exposureType: 'direct' | 'indirect';
}
interface ExposureSectionProps {
  exposures: IExposure[];
  chartData: any;
  exposureRisk: any;
  sum: number;
}

const customTextPlugin = {
  id: 'customText',
  afterDraw: (chart) => {
    const {
      ctx,
      chartArea: { top, left }
    } = chart;
    const datasets = chart.data.datasets;

    const directData = datasets.find((d) => d.label === 'Direct')?.data;
    const indirectData = datasets.find((d) => d.label === 'Indirect')?.data;

    ctx.save();
    ctx.font = '14px Arial';
    ctx.fillStyle = '#666';
    ctx.textAlign = 'left';

    // Only show Direct if it has non-zero values
    if (directData != null && (directData as number[]).some((value: number) => value > 0)) {
      ctx.fillText('Direct', left + 35, top + 60);
    }

    // Only show Indirect if it has non-zero values
    if (indirectData != null && (indirectData as number[]).some((value: number) => value > 0)) {
      ctx.fillText('Indirect', left + 35, top + 72);
    }

    ctx.restore();
  }
};

// Register the plugin
ChartJS.register(ArcElement, Tooltip, Legend, customTextPlugin);

const sortExposures = (exposures: IExposure[], exposureRisk: any): IExposure[] => {
  const clonedExposures = [...exposures];
  return clonedExposures.sort((a, b) => {
    const categoryA = exposureRisk.get(b.category);
    const categoryB = exposureRisk.get(a.category);
    if (categoryA === categoryB) {
      return b.value - a.value;
    }
    return RiskRank[categoryA] - RiskRank[categoryB];
  });
};

const ExposureSection: React.FC<ExposureSectionProps> = ({ exposures, chartData, exposureRisk, sum }) => {
  const sortedExposures = sortExposures(exposures, exposureRisk);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  const options = {
    cutout: '70%',
    radius: '90%',
    circumference: 270,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.chart.data.labels[context.dataIndex];
            const value = context.raw;
            return `${label}: ${value}%`;
          }
        }
      },
      legend: {
        position: 'right' as const,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 6,
          boxHeight: 6,
          color: '#666666',
          padding: 15,
          generateLabels: (chart) => {
            return chart.data.labels.map((label, i) => ({
              text: `${label}`,
              fillStyle: chart.data.datasets[0].backgroundColor[i],
              strokeStyle: chart.data.datasets[0].borderColor[i],
              lineWidth: 1,
              hidden: false,
              index: i
            }));
          }
        }
      },
      customText: customTextPlugin
    },
    layout: {
      padding: {
        top: 40,
        left: 40
      }
    },
    datasets: {
      doughnut: {
        spacing: 10
      }
    }
  };

  if (exposures != null && exposures.length === 0) {
    return (
      <Grid templateColumns="auto" justifyItems={'center'} pt={4} pb={4}>
        <GridItem pb={4}>
          <Icon icon="simple-line-icons:exclamation" />
        </GridItem>
        <GridItem>
          <Text>No exposure data available</Text>
        </GridItem>
      </Grid>
    );
  }

  return (
    <Flex flexWrap="nowrap" gap={3} alignItems="center">
      <Box flexShrink={0}>
        <Doughnut data={chartData} options={options} />
      </Box>
      <Box
        flex={1}
        css={css`
          overflow-x: auto;
          &::-webkit-scrollbar {
            height: 8px;
          }
          &::-webkit-scrollbar-track {
            background: #f7f7f7;
            border-radius: 4px;
          }
          &::-webkit-scrollbar-thumb {
            background: #e0e0e0;
            border-radius: 4px;
            &:hover {
              background: #cecece;
            }
          }
        `}
      >
        <TableContainer border="1px solid #E0E0E0" minWidth="750px">
          <Table size="sm">
            <Thead bg="#F7F7F7">
              <Tr>
                <Th borderBottom="1px solid #E0E0E0">Category</Th>
                <Th borderBottom="1px solid #E0E0E0">Risk</Th>
                <Th borderBottom="1px solid #E0E0E0">Type</Th>
                <Th isNumeric borderBottom="1px solid #E0E0E0">
                  Value
                </Th>
                <Th isNumeric borderBottom="1px solid #E0E0E0">
                  Percent (%)
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedExposures.map(function (d, idx) {
                return (
                  <Tr key={idx}>
                    <Td borderBottom="1px solid #E0E0E0">{CATEGORY_NAMES[d.category] ?? d.category}</Td>
                    <Td borderBottom="1px solid #E0E0E0">
                      <Tag bg={`risk.${exposureRisk.get(d.category).toUpperCase()}`} color="white">
                        {exposureRisk.get(d.category)}
                      </Tag>
                    </Td>
                    <Td borderBottom="1px solid #E0E0E0">{d.exposureType}</Td>
                    <Td isNumeric borderBottom="1px solid #E0E0E0">
                      {formatter.format(d.value)}
                    </Td>
                    <Td borderBottom="1px solid #E0E0E0">
                      {(d.value / sum) * 100 < 1 ? '<1' : ((d.value / sum) * 100).toFixed(2)}%
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
            <Tfoot bg="#F7F7F7">
              <Tr>
                <Th fontSize={'md'}>TOTAL</Th>
                <Th fontSize={'md'}></Th>
                <Th fontSize={'md'}></Th>
                <Th fontSize={'md'} isNumeric>
                  {formatter.format(sum)}
                </Th>
                <Th fontSize={'md'} isNumeric>
                  100%
                </Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </Box>
    </Flex>
  );
};

export default ExposureSection;

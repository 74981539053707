import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Skeleton,
  Spacer,
  Text,
  Tooltip,
  VStack,
  useDisclosure
} from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { Footer } from '@features/shared/components';
import { selectActiveOrgID, selectUser, userClearOrgStateAction } from '@features/user-settings/userSlice';
import { useGetMeQuery, useGetOrganizationQuery } from '@services/canaria.services';

import { MODE } from '../env';

interface SidePanelProps {
  children?: React.ReactNode;
}

const UserDisplay: React.FC<{ userName: string; email: string; collapsed: boolean }> = ({
  userName,
  email,
  collapsed
}) => {
  const dispatch = useDispatch();

  return (
    <Menu>
      <MenuButton as={Button} variant="unstyled" _focus={{ boxShadow: 'none' }} _hover={{ bg: 'transparent' }}>
        <Flex align="center" justify="center">
          <Avatar size="md" name={userName} mr={2} />
          {!collapsed && (
            <Flex direction="column" align="flex-start">
              <Text fontWeight="normal" color="sidebar.secondaryText" fontSize="sm">
                Welcome back
              </Text>
              <Text fontWeight="bold">{userName}</Text>
            </Flex>
          )}
        </Flex>
      </MenuButton>
      <MenuList zIndex={2} color="sidebar.userMenu.text" borderRadius="8px">
        <MenuItem _hover={{ background: 'none' }} cursor="default">
          <Avatar size="md" name={userName} mr={2} />
          <Flex direction="column" align="flex-start">
            <Text fontWeight="bold">{userName}</Text>
            <Text fontWeight="normal" color="link" fontSize="sm">
              {email}
            </Text>
          </Flex>
        </MenuItem>
        <MenuDivider />
        <MenuItem
          onClick={() => {
            dispatch(userClearOrgStateAction());
          }}
        >
          <Icon icon="fe:sync" style={{ marginRight: '5px' }} />
          <Text fontWeight="normal">Switch Organization</Text>
        </MenuItem>
        <MenuItem
          onClick={() => {
            const baseUrl =
              MODE === 'development'
                ? 'http://127.0.0.1:8000'
                : MODE === 'staging'
                  ? 'https://backend-dev.canariaconsulting.com'
                  : 'https://backend.canariaconsulting.com';
            window.location.replace(`${baseUrl}/acct/change-password/`);
          }}
        >
          <Icon icon="bx:key" style={{ marginRight: '5px' }} />
          <Text fontWeight="normal">Change Password</Text>
        </MenuItem>
        <MenuDivider />
        <Link to="/logout">
          <MenuItem>
            <Flex align="center">
              <Icon icon="mdi:logout" style={{ marginRight: '5px' }} />
              <Text>Logout</Text>
            </Flex>
          </MenuItem>
        </Link>
      </MenuList>
    </Menu>
  );
};

const AnimatedMenuItem: React.FC<{
  icon: React.ReactNode;
  label: string;
  route: string;
  collapsed: boolean;
  onMenuClick?: () => void;
}> = ({ icon, label, route, collapsed, onMenuClick }) => {
  const location = useLocation();
  const isActive = location.pathname.startsWith(route);

  return (
    <Tooltip
      label={label}
      placement="right"
      isDisabled={!collapsed}
      hasArrow
      bg="gray.700"
      color="white"
      borderRadius="md"
      px={3}
      py={2}
    >
      <Link
        to={route}
        style={{ textDecoration: 'none', width: '100%' }}
        onClick={() => {
          if (onMenuClick != null) onMenuClick();
        }}
      >
        <Flex
          minH="40px"
          bg={isActive ? 'button.sidebarHover' : 'transparent'}
          _hover={{ bg: 'button.sidebarHover' }}
          borderRadius="md"
          p={2}
          transition="all 0.2s ease-in-out"
          justify={collapsed ? 'center' : 'flex-start'}
          align="center"
          width="100%"
        >
          {icon}
          <Text
            ml={2}
            fontSize="md"
            opacity={collapsed ? 0 : 1}
            transition={collapsed ? 'none' : 'opacity 0.2s ease-in-out'}
            position={collapsed ? 'absolute' : 'relative'}
            pointerEvents={collapsed ? 'none' : 'auto'}
          >
            {label}
          </Text>
        </Flex>
      </Link>
    </Tooltip>
  );
};

const SidePanel: React.FC<SidePanelProps> = ({ children }) => {
  const activeOrgID = useSelector(selectActiveOrgID);
  const { isLoading: meIsLoading } = useGetMeQuery(null);
  const { isLoading: orgIsLoading } = useGetOrganizationQuery(activeOrgID as string);
  if (!meIsLoading && !orgIsLoading) {
    // eslint-disable-next-line no-console
    console.log('Loaded Me and Org');
  }
  const [collapsed, setCollapsed] = useState(true);
  const userState = useSelector(selectUser);
  const { onToggle } = useDisclosure();

  const handleToggle = (): void => {
    setCollapsed((prev) => !prev);
    onToggle();
  };

  const handleMenuClick = (): void => {
    if (!collapsed) {
      setCollapsed(true);
      onToggle();
    }
  };

  return (
    <Flex h="100vh" overflow="hidden">
      <Box
        width={collapsed ? '80px' : '220px'}
        minWidth={collapsed ? '80px' : '220px'}
        bg="sidebar.background"
        color="sidebar.text"
        p={4}
        transition="width 0.2s ease-in-out, min-width 0.2s ease-in-out"
        position="relative"
        display="flex"
        flexDirection="column"
      >
        <Box height="100px">
          <Box
            position="absolute"
            transition="left 0.2s ease-in-out"
            left={collapsed ? '60px' : '205px'}
            top="20px"
            zIndex={2}
          >
            <IconButton
              aria-label="Toggle Menu"
              icon={
                collapsed ? (
                  <Icon icon="tabler:layout-sidebar-left-expand-filled" />
                ) : (
                  <Icon icon="tabler:layout-sidebar-left-collapse-filled" />
                )
              }
              fontSize="20px"
              onClick={handleToggle}
              size="md"
              bg="button.secondary"
            />
          </Box>
          <Flex direction="row" alignItems="center">
            {isEmpty(userState.orgImageUrl) ? (
              <Skeleton height="50px" width="50px" mr={2} />
            ) : (
              <Image src={userState.orgImageUrl} alt={userState.orgName} boxSize="50px" mr={2} />
            )}
            {!collapsed && (
              <>
                <Text>{userState.orgName}</Text>
                <Spacer />
              </>
            )}
          </Flex>
        </Box>

        <VStack align="stretch" spacing={4}>
          <VStack spacing={2} align="flex-start">
            <AnimatedMenuItem
              icon={<Icon icon="material-symbols-light:group" />}
              label="Profiles"
              route="/dashboard/profiles"
              collapsed={collapsed}
              onMenuClick={handleMenuClick}
            />
            <AnimatedMenuItem
              icon={<Icon icon="simple-line-icons:wallet" />}
              label="Wallets"
              route="/dashboard/wallets"
              collapsed={collapsed}
              onMenuClick={handleMenuClick}
            />
            <AnimatedMenuItem
              icon={<Icon icon="mingcute:search-line" />}
              label="Screening"
              route="/dashboard/screening"
              collapsed={collapsed}
              onMenuClick={handleMenuClick}
            />
            <AnimatedMenuItem
              icon={<Icon icon="mdi:notifications-none" />}
              label="Alerts"
              route="/dashboard/alerts"
              collapsed={collapsed}
              onMenuClick={handleMenuClick}
            />
          </VStack>
        </VStack>
        <Box marginTop="auto">
          <Divider marginY={2} />
          <UserDisplay
            userName={`${userState.firstName} ${userState.lastName}`}
            email={userState.email}
            collapsed={collapsed}
          />
        </Box>
      </Box>

      <Divider orientation="vertical" />

      {/* <Box flex="1" bg={bgValue} display="flex" flexDirection="column" overflowY="auto"> */}
      <Box flex="1" bg="background" display="flex" flexDirection="column" overflowY="auto">
        <Box>
          <Box mt={5}>{children}</Box>
        </Box>
        <Footer />
      </Box>
    </Flex>
  );
};

export default SidePanel;

import { Box, Heading, Grid, GridItem, Text, Table, Thead, Tbody, Tr, Th, Td, TableContainer } from '@chakra-ui/react';
import { css } from '@emotion/react';

const TriggersSection: React.FC<{ triggers: any[] }> = ({ triggers }) => {
  return (
    <Box outline="1px solid #E0E0E0" mt={4} pl={5} pr={5} pt={4} pb={4} bg="white" layerStyle="container">
      <Heading size="sm">Triggers</Heading>
      {triggers != null && triggers.length === 0 ? (
        <Grid templateColumns="auto" pt={4} pb={4}>
          <GridItem>
            <Text>No triggers found</Text>
          </GridItem>
        </Grid>
      ) : (
        <Box
          pt={5}
          css={css`
            overflow-x: auto;
            &::-webkit-scrollbar {
              height: 8px;
            }
            &::-webkit-scrollbar-track {
              background: #f7f7f7;
              border-radius: 4px;
            }
            &::-webkit-scrollbar-thumb {
              background: #e0e0e0;
              border-radius: 4px;
              &:hover {
                background: #cecece;
              }
            }
          `}
        >
          <TableContainer border="1px solid #E0E0E0" minWidth="750px">
            <Table size="sm">
              <Thead bg="#F7F7F7">
                <Tr>
                  <Th borderBottom="1px solid #E0E0E0">Category</Th>
                  <Th borderBottom="1px solid #E0E0E0">% of Total Exposure</Th>
                  <Th borderBottom="1px solid #E0E0E0">Message</Th>
                  <Th borderBottom="1px solid #E0E0E0">Risk</Th>
                  <Th borderBottom="1px solid #E0E0E0">Min Threshold</Th>
                  <Th borderBottom="1px solid #E0E0E0">Max Threshold</Th>
                </Tr>
              </Thead>
              <Tbody>
                {triggers != null ? (
                  triggers.map((trigger, idx) => (
                    <Tr key={idx}>
                      <Td borderBottom="1px solid #E0E0E0">{trigger.category}</Td>
                      <Td borderBottom="1px solid #E0E0E0">{trigger.percentage}</Td>
                      <Td borderBottom="1px solid #E0E0E0">{trigger.message}</Td>
                      <Td borderBottom="1px solid #E0E0E0">{trigger.ruleTriggered.risk}</Td>
                      <Td borderBottom="1px solid #E0E0E0">{trigger.ruleTriggered.minThreshold}</Td>
                      <Td borderBottom="1px solid #E0E0E0">{trigger.ruleTriggered.maxThreshold}</Td>
                    </Tr>
                  ))
                ) : (
                  <></>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default TriggersSection;

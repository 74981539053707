import { Box } from '@chakra-ui/react';

import { PROFILE_STATUS_DESCRIPTION } from '@models/profileTypes';

const StatusBadge: React.FC<{ status: string }> = ({ status }) => {
  const statusStyles = {
    PR: {
      color: '#4A5568',
      background: 'transparent',
      borderColor: '#4A5568'
    },
    UR: {
      color: '#FFFFFF',
      background: '#718096',
      borderColor: '#718096'
    },
    RS: {
      color: '#2C7A7B',
      background: 'transparent',
      borderColor: '#2C7A7B'
    },
    RR: {
      color: '#FFFFFF',
      background: '#319795',
      borderColor: '#319795'
    },
    ES: {
      color: '#744209',
      background: '#FEEBCB',
      borderColor: '#FEEBCB'
    },
    AP: {
      color: '#FFFFFF',
      background: '#00A676',
      borderColor: '#00A676'
    },
    DE: {
      color: '#FFFFFF',
      background: '#D72638',
      borderColor: '#D72638'
    },
    AR: {
      color: '#0A202C',
      background: '#EDF2F7',
      borderColor: '#EDF2F7'
    }
  };

  const style = statusStyles[status] ?? {
    color: '#7C7E7E',
    background: '#7C7E7E33',
    borderColor: '#7C7E7E'
  };

  return (
    <Box
      bg={style.background}
      color={style.color}
      border={`1px solid ${style.borderColor}`}
      borderRadius="md"
      px={2}
      py={1}
      textAlign="center"
      fontSize="sm"
      maxW="48"
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {PROFILE_STATUS_DESCRIPTION[status] ?? 'Unknown Status'}
    </Box>
  );
};

export default StatusBadge;

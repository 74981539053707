import { useToast } from '@chakra-ui/react';
import * as Sentry from '@sentry/browser';
import { useNavigate } from 'react-router-dom';

import analytics from '@features/analytics/analytics';
import { ALERT_STATUS } from '@features/panels/alertsPanel/constants';
import { useUpdateAlertMutation, useReassignAlertMutation } from '@services/canaria.services';

export const useAlertActions = (
  alertId: number,
  activeOrgID: number,
  notes: string,
  status: ALERT_STATUS
): {
  handleAlertStatusUpdate: (newStatus: ALERT_STATUS) => Promise<void>;
  onReassign: () => Promise<void>;
  updateAlertIsLoading: boolean;
  updateAlertNotes: (notes: string) => Promise<void>;
} => {
  const [updateAlert, { isLoading: updateAlertIsLoading }] = useUpdateAlertMutation();
  const [reassignAlert] = useReassignAlertMutation();
  const toast = useToast();
  const navigate = useNavigate();

  const getStatusText = (newStatus: ALERT_STATUS): string => {
    switch (newStatus) {
      case ALERT_STATUS.OPEN:
        return 'Open';
      case ALERT_STATUS.CLOSED:
        return 'Closed';
      case ALERT_STATUS.ESCALATED:
        return 'Escalated';
      case ALERT_STATUS.UNDER_REVIEW:
        return 'Under Review';
    }
  };

  const handleAlertStatusUpdate = async (newStatus: ALERT_STATUS): Promise<void> => {
    try {
      if ((newStatus === ALERT_STATUS.CLOSED || newStatus === ALERT_STATUS.ESCALATED) && notes.length === 0) {
        toast({
          status: 'error',
          title: 'Error',
          description: `Please add notes before ${newStatus === ALERT_STATUS.CLOSED ? 'closing' : 'escalating'} the alert!`,
          isClosable: true
        });
        return;
      }

      await updateAlert({
        orgId: activeOrgID.toString(),
        alertId: alertId.toString(),
        status: newStatus,
        notes: newStatus === ALERT_STATUS.CLOSED ? notes : undefined
      }).unwrap();

      analytics.trackAlertStatusChange({
        alertId: alertId.toString(),
        fromStatus: status,
        toStatus: newStatus
      });

      toast({
        status: 'success',
        title: 'Success',
        description: `Alert status changed to ${getStatusText(newStatus)}`,
        isClosable: true
      });

      if (newStatus === ALERT_STATUS.CLOSED) {
        navigate('/dashboard/alerts');
      }
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Oh no, there was an error!',
        isClosable: true
      });
      Sentry.captureException(err);
    }
  };

  const onReassign = async (): Promise<void> => {
    try {
      await reassignAlert({
        orgId: activeOrgID.toString(),
        alertId: alertId.toString()
      }).unwrap();
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Failed to reassign alert',
        isClosable: true
      });
      Sentry.captureException(err);
    }
  };
  const updateAlertNotes = async (notes: string): Promise<void> => {
    try {
      await updateAlert({
        orgId: activeOrgID.toString(),
        alertId: alertId.toString(),
        notes
      }).unwrap();
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Failed to update alert notes',
        isClosable: true
      });
      Sentry.captureException(err);
    }
  };

  return { handleAlertStatusUpdate, onReassign, updateAlertIsLoading, updateAlertNotes };
};

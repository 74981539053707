import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  VStack,
  Tooltip,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Divider,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import dayjs from 'dayjs';
import { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import 'react-quill/dist/quill.snow.css';

import { selectActiveOrgID } from '@features/user-settings/userSlice';
import {
  useGetProfileNotesQuery,
  useCreateProfileNoteMutation,
  useUpdateProfileNoteMutation,
  useDeleteProfileNoteMutation
} from '@services/canaria.services';
import { smoothScrollbarStyles } from '@utils/consts';

interface ProfileNotesProps {
  profileId: string;
}

const ProfileNotes: React.FC<ProfileNotesProps> = ({ profileId }) => {
  const toast = useToast();
  const activeOrgID = useSelector(selectActiveOrgID);
  if (activeOrgID == null) {
    throw new Error('No active org ID found');
  }
  const { data: notes = [], refetch } = useGetProfileNotesQuery({
    orgId: activeOrgID,
    profileId
  });
  const [createProfileNote] = useCreateProfileNoteMutation();
  const [updateProfileNote] = useUpdateProfileNoteMutation();
  const [deleteProfileNote] = useDeleteProfileNoteMutation();

  const [newNote, setNewNote] = useState<string>('');
  const [editNoteId, setEditNoteId] = useState<string | null>(null);
  const [editNote, setEditNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState<string | null>(null);
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { isOpen: isExpandedOpen, onOpen: onExpandedOpen, onClose: onExpandedClose } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const cancelDeleteRef = useRef(null);

  const handleAddNote = async (): Promise<void> => {
    setLoading(true);
    try {
      if (newNote.trim().length > 0) {
        await createProfileNote({
          orgId: activeOrgID,
          profileId,
          content: newNote
        }).unwrap();
        setNewNote('');
        await refetch();
        toast({
          title: 'Note created.',
          status: 'success',
          duration: 3000,
          isClosable: true
        });
        onAddClose();
      }
    } catch (error) {
      toast({
        title: 'Error creating note.',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateNote = async (): Promise<void> => {
    try {
      if (editNote.trim().length > 0 && editNoteId != null) {
        await updateProfileNote({
          orgId: activeOrgID,
          profileId,
          noteId: editNoteId,
          content: editNote
        }).unwrap();
        setEditNoteId(null);
        setEditNote('');
        await refetch();
        toast({
          title: 'Note updated.',
          status: 'success',
          duration: 3000,
          isClosable: true
        });
        onEditClose();
      }
    } catch (error) {
      toast({
        title: 'Error updating note.',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };

  const handleDeleteNote = async (noteId: string): Promise<void> => {
    try {
      await deleteProfileNote({
        orgId: activeOrgID,
        profileId,
        noteId
      }).unwrap();
      await refetch();
      toast({
        title: 'Note deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    } catch (error) {
      toast({
        title: 'Error deleting note.',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    } finally {
      onDeleteClose();
      setNoteToDelete(null);
    }
  };

  return (
    <VStack spacing={4} align="stretch" w="100%" layerStyle="subContainer">
      <Flex justifyContent="space-between" alignItems="center" w="100%">
        <Heading as="h1" size="md" textAlign="left" display="flex" alignItems="center">
          Notes
        </Heading>
        <Flex gap={2}>
          <Button onClick={onAddOpen} bg="transparent">
            + Add Note
          </Button>
          <IconButton
            aria-label="Expand notes"
            icon={<Icon icon="bx:expand" />}
            size="sm"
            variant="ghost"
            onClick={onExpandedOpen}
          />
        </Flex>
      </Flex>

      <Modal isOpen={isAddOpen} onClose={onAddClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW="600px" w="90%">
          <ModalHeader>Add New Note</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody py={4}>
            <Text fontSize="sm" fontWeight={600} mb={2}>
              Note
            </Text>
            <ReactQuill value={newNote} onChange={setNewNote} style={{ height: '200px', marginBottom: '40px' }} />
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button mr={3} onClick={onAddClose} variant="secondaryLarge">
              Cancel
            </Button>
            <Button onClick={handleAddNote} isLoading={loading} variant="primaryLarge">
              Add Note
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isEditOpen} onClose={onEditClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW="600px" w="90%">
          <ModalHeader>Edit Note</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody py={4}>
            <Text fontSize="sm" fontWeight={600} mb={2}>
              Note
            </Text>
            <ReactQuill value={editNote} onChange={setEditNote} style={{ height: '200px', marginBottom: '40px' }} />
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button mr={3} onClick={onEditClose} variant="secondaryLarge">
              Cancel
            </Button>
            <Button onClick={handleUpdateNote} variant="primaryLarge">
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isExpandedOpen} onClose={onExpandedClose} size="6xl">
        <ModalOverlay />
        <ModalContent maxW="1200px" maxH="90vh">
          <ModalHeader>Notes</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>
            <Flex direction="column" gap={4} overflowY="auto" maxH="70vh">
              <Button onClick={onAddOpen} alignSelf="flex-end" bg="transparent">
                + Add Note
              </Button>
              {notes?.map((note) => (
                <Box
                  key={note.id}
                  px={3}
                  py={2}
                  shadow="md"
                  borderWidth="1px"
                  w="100%"
                  position="relative"
                  borderRadius="md"
                >
                  <Flex direction="column" gap={2}>
                    <Flex justifyContent="space-between" alignItems="center">
                      <Tooltip
                        label={`Updated by ${note.updatedBy}`}
                        placement="top"
                        isDisabled={note.updatedBy === ''}
                      >
                        <Text fontSize="xs" color="gray.500">
                          Created at {dayjs(note.createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                        </Text>
                      </Tooltip>
                      <Flex gap={4}>
                        <Text
                          fontSize="xs"
                          fontWeight={600}
                          color="blue.500"
                          cursor="pointer"
                          onClick={() => {
                            setEditNoteId(note.id);
                            setEditNote(note.content);
                            onEditOpen();
                          }}
                        >
                          Edit
                        </Text>
                        <Text
                          fontSize="xs"
                          fontWeight={600}
                          color="red.500"
                          cursor="pointer"
                          onClick={() => {
                            setNoteToDelete(note.id);
                            onDeleteOpen();
                          }}
                        >
                          Delete
                        </Text>
                      </Flex>
                    </Flex>

                    <Box>
                      <div dangerouslySetInnerHTML={{ __html: note.content }} />
                    </Box>

                    <Text fontSize="xs" color="gray.500">
                      Created by {note.createdBy}
                    </Text>
                  </Flex>
                </Box>
              ))}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <AlertDialog isOpen={isDeleteOpen} leastDestructiveRef={cancelDeleteRef} onClose={onDeleteClose} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Note
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure you want to delete this note? This action cannot be undone.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelDeleteRef} onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={async () => {
                  if (noteToDelete != null) {
                    await handleDeleteNote(noteToDelete);
                  }
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Divider />

      <Box maxH="300px" overflowY="auto" position="relative" sx={smoothScrollbarStyles}>
        {notes?.map((note) => (
          <Box key={note.id} px={3} py={2} shadow="md" borderWidth="1px" w="100%" position="relative" mb={2}>
            <Flex direction="column" gap={2}>
              <Flex justifyContent="space-between" alignItems="center">
                <Tooltip label={`Updated by ${note.updatedBy}`} placement="top" isDisabled={note.updatedBy === ''}>
                  <Text fontSize="xs" color="gray.500">
                    Created at {dayjs(note.createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                  </Text>
                </Tooltip>
                <Flex gap={4}>
                  <Text
                    fontSize="xs"
                    fontWeight={600}
                    color="blue.500"
                    cursor="pointer"
                    onClick={() => {
                      setEditNoteId(note.id);
                      setEditNote(note.content);
                      onEditOpen();
                    }}
                  >
                    Edit
                  </Text>
                  <Text
                    fontSize="xs"
                    fontWeight={600}
                    color="red.500"
                    cursor="pointer"
                    onClick={() => {
                      setNoteToDelete(note.id);
                      onDeleteOpen();
                    }}
                  >
                    Delete
                  </Text>
                </Flex>
              </Flex>

              <Box>
                <div dangerouslySetInnerHTML={{ __html: note.content }} />
              </Box>

              <Text fontSize="xs" color="gray.500">
                Created by {note.createdBy}
              </Text>
            </Flex>
          </Box>
        ))}
      </Box>
    </VStack>
  );
};

export default ProfileNotes;

import { type Props, Select } from 'chakra-react-select';

interface SelectOption {
  value: any;
  label: string;
}

interface GroupedOption {
  label: string;
  options: Array<SelectOption | GroupedOption>;
}

const Styles = {
  container: (provided: any) => ({
    ...provided,
    backgroundColor: '#F7F7F7'
  }),
  control: (provided: any) => ({
    ...provided,
    border: 'none',
    backgroundColor: '#F7F7F7',
    boxShadow: 'none',
    opacity: '1 !important',
    '&:hover': {
      border: 'none'
    }
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected === true ? '#E0E0E0' : '#F7F7F7',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#E0E0E0'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    backgroundColor: '#F7F7F7',
    '&:hover': {
      backgroundColor: '#F7F7F7'
    }
  })
};

function flattenOptions(options: Array<SelectOption | GroupedOption>): SelectOption[] {
  return options.reduce<SelectOption[]>((acc, option) => {
    if ('options' in option) {
      return [...acc, ...flattenOptions(option.options)];
    }
    return [...acc, option];
  }, []);
}

export function StyledSelect({ value: propsValue, ...props }: Props): JSX.Element {
  // for drop in compatibility with classic select
  // we build a flat list of options
  const flatOptions = props.options != null ? flattenOptions(props.options as Array<SelectOption | GroupedOption>) : [];

  // and we build a value object that is compatible with chakra-react-select
  // the empty string check is so we render the placeholder on initial empty values
  const value =
    propsValue != null && propsValue !== '' && typeof propsValue !== 'object'
      ? {
          value: propsValue,
          label: flatOptions.find((option) => option.value === propsValue)?.label
        }
      : propsValue;

  return <Select chakraStyles={Styles} value={value} {...props} />;
}

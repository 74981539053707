import { useToast } from '@chakra-ui/react';
import type { QueryActionCreatorResult, QueryDefinition } from '@reduxjs/toolkit/query';
import * as Sentry from '@sentry/browser';

import { ALERT_STATUS } from '@features/panels/alertsPanel/constants';
import { useUpdateProfileInquiryMutation, useReassignAlertMutation } from '@services/canaria.services';

export const useProfileInquiryActions = (
  activeOrgID: string,
  alertData: any,
  refetch: () => QueryActionCreatorResult<QueryDefinition<any, any, any, any>>,
  handleAlertStatusUpdate: (newStatus: ALERT_STATUS) => Promise<void>
): {
  onResolveMatch: (inquiryId: string, resolution: 'TRUE_MATCH' | 'FALSE_POSITIVE', notes: string) => Promise<void>;
} => {
  const toast = useToast();
  const [updateProfileInquiry] = useUpdateProfileInquiryMutation();
  const [reassignAlert] = useReassignAlertMutation();

  const onResolveMatch = async (
    inquiryId: string,
    resolution: 'TRUE_MATCH' | 'FALSE_POSITIVE',
    notes: string
  ): Promise<void> => {
    try {
      await updateProfileInquiry({
        orgId: activeOrgID,
        profileId: alertData.profile.id,
        inquiryId,
        resolution,
        notes
      }).unwrap();

      // Check if this is the first resolved match
      // or if it's assigned to nobody so we can auto-assign it to the current user
      const isFirstResolvedMatch: boolean =
        alertData.profileInquiries?.every((inquiry) => inquiry.resolution === 'NO_RESOLUTION') === true ||
        alertData.reviewedBy === null;

      if (isFirstResolvedMatch && alertData.status === ALERT_STATUS.OPEN) {
        // Update alert status to "under review"
        await handleAlertStatusUpdate(ALERT_STATUS.UNDER_REVIEW);
        // assign the alert to the current user
        await reassignAlert({
          orgId: activeOrgID,
          alertId: alertData.id
        }).unwrap();
      }

      toast({
        status: 'success',
        title: 'Success',
        description: `Match resolved as ${resolution === 'TRUE_MATCH' ? 'True Match' : 'False Positive'}`,
        isClosable: true
      });

      await refetch();
    } catch (err) {
      toast({
        status: 'error',
        title: 'Error',
        description: 'Failed to resolve match',
        isClosable: true
      });
      Sentry.captureException(err);
      throw err;
    }
  };

  return { onResolveMatch };
};

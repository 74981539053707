import { createContext, useContext, type ReactNode, useState, useEffect } from 'react';

interface NotesContextType {
  quillValue: string;
  setQuillValue: (value: string) => void;
  isEditingNotes: boolean;
  setIsEditingNotes: (value: boolean) => void;
}

export const NotesContext = createContext<NotesContextType | undefined>(undefined);

export const NotesProvider: React.FC<{
  children: ReactNode;
  initialNotes: string;
  initialIsEditingNotes: boolean;
}> = ({ children, initialNotes, initialIsEditingNotes }) => {
  const [quillValue, setQuillValue] = useState(initialNotes);
  const [isEditingNotes, setIsEditingNotes] = useState(initialIsEditingNotes);

  useEffect(() => {
    setQuillValue(initialNotes);
    setIsEditingNotes(false);
  }, [initialNotes]);

  return (
    <NotesContext.Provider
      value={{
        quillValue,
        setQuillValue,
        isEditingNotes,
        setIsEditingNotes
      }}
    >
      {children}
    </NotesContext.Provider>
  );
};

export const useNotes = (): NotesContextType => {
  const context = useContext(NotesContext);
  if (context === undefined) {
    throw new Error('useNotes must be used within a NotesProvider');
  }
  return context;
};

import { Flex, Link, Text, useDisclosure } from '@chakra-ui/react';
import { Icon } from '@iconify/react';

import CookiePreferences from '@features/shared/components/CookiePreferences';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex
      as="footer"
      justify="space-between"
      align="center"
      padding="1.5rem"
      mt="auto"
      bg="footer.background"
      color="footer.text"
    >
      <Text fontSize="sm" color="footer.text">
        © 2023-{currentYear} Canaria Consulting. All rights reserved
      </Text>
      <Flex>
        <Link href="#" fontSize="sm" color="gray.500" marginRight="1rem" onClick={onOpen}>
          Cookie settings
        </Link>
        <Link href="/privacy-policy" fontSize="sm" color="gray.500" marginRight="1rem">
          Privacy policy
        </Link>
        <Link href="https://www.linkedin.com/company/canaria-consulting/" isExternal>
          <Icon icon="fa6-brands:linkedin-in" style={{ marginRight: '1rem', width: '24px', height: '24px' }} />
        </Link>
        <Link href="https://www.instagram.com/canariaco/" isExternal>
          <Icon icon="fa6-brands:instagram" style={{ marginRight: '1rem', width: '24px', height: '24px' }} />
        </Link>
      </Flex>
      <CookiePreferences isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};

export default Footer;

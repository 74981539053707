import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Divider
} from '@chakra-ui/react';

import { useProfileForm } from '@hooks/useProfileForm';
import { useCreateRelatedProfileMutation } from '@services/canaria.services';

import { AddPrimaryPartyForm } from '../AddPrimaryParty';

interface AddRelatedPartyModalProps {
  isOpen: boolean;
  onClose: () => void;
  orgId: string;
  profileId: string;
  profileGroupId: string;
}

const AddRelatedPartyModal: React.FC<AddRelatedPartyModalProps> = ({
  isOpen,
  onClose,
  orgId,
  profileId,
  profileGroupId
}) => {
  const [createRelatedProfile] = useCreateRelatedProfileMutation();

  const { handleSubmit } = useProfileForm({
    orgId,
    onBefore: async (values) => ({
      ...values,
      profileGroup: profileGroupId,
      isPrimary: false
    }),
    onSuccess: async (createdProfileId, values) => {
      await createRelatedProfile({
        orgId,
        profileId,
        toProfile: createdProfileId,
        relationTypes: values.relationTypes.map((relationType) => relationType.value)
      }).unwrap();
      onClose();
    }
  });

  const handleProfileRelatedSubmit = async (values): Promise<void> => {
    if (values.formMode === 'ADD_EXISTING_PROFILES') {
      for (const { profile, relationships } of values.selectedProfiles) {
        await createRelatedProfile({
          orgId,
          profileId,
          toProfile: profile.id,
          relationTypes: relationships.map((relationType) => relationType.value)
        }).unwrap();
        onClose();
      }
    } else {
      await handleSubmit(values);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pt={6} pr={12} position="relative">
          Add Related Party
          <ModalCloseButton top={7} right={6} />
        </ModalHeader>
        <ModalBody>
          <Divider />
          <AddPrimaryPartyForm onSubmit={handleProfileRelatedSubmit} isRelatedParty={true} />
        </ModalBody>
        <ModalFooter pt={6} pr={6}>
          <Button mr={3} onClick={onClose} variant="secondaryLarge">
            Cancel
          </Button>
          <Button type="submit" form="IndividualPrimaryParty" variant="primaryLarge">
            Add Related Party
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddRelatedPartyModal;

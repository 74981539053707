import { CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, VStack, useDisclosure, Divider, useToast, Input, Text, IconButton } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { StyledSelect } from '@components/common/StyledSelect';
import { FormField } from '@components/FormField';

import { useProfileWizard } from '@context/ProfileWizardContext';
import AttachmentModal from '@features/profile-wizard/components/common/AddAttachmentModal';
import { Loading } from '@features/shared/components';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { useProfileForm } from '@hooks/useProfileForm';
import useQueryParams from '@hooks/useQueryParams';
import { PROFILE_TYPE, type IProfile } from '@models/profileTypes';
import { useGetProfileAttachmentsQuery, useGetProfilesQuery } from '@services/canaria.services';
import { convertObjectKeysToCamelCase } from '@services/utils';
import { RELATION_TYPE_OPTIONS } from '@utils/consts';

import { IndividualPrimaryPartyForm, EntityPrimaryPartyForm } from './forms/primaryParty';

interface IProfileWithRelationType extends IProfile {
  relationTypes?: string[];
}

interface ProfileFormProps {
  profile?: IProfileWithRelationType;
  onSubmit: (values: any) => Promise<void>;
  isRelatedParty?: boolean;
  relationTypes?: string[];
}

interface SelectedProfile {
  profile: IProfile;
  relationships: any[];
}

interface SearchResultsRelatedProfilesProps {
  profiles: IProfile[];
  selectedProfiles: SelectedProfile[];
  onAddExistingProfile: (profile: IProfile) => void;
  onDisplayCreateForm: (value: boolean) => void;
  isFetching: boolean;
  relationTypes: string[];
}

interface AddExistingRelatedPartyFormProps {
  searchValue: string;
  form: any;
  profileType: string;
  relationTypes: string[];
  setDisplayCreateForm: (value: boolean) => void;
}

export const SearchResultsRelatedProfiles: React.FC<SearchResultsRelatedProfilesProps> = ({
  profiles,
  onAddExistingProfile,
  selectedProfiles,
  onDisplayCreateForm,
  isFetching
}) => {
  if (isFetching) {
    return <Loading />;
  }

  return (
    <Box>
      {profiles.length > 0 ? (
        <>
          <Text fontSize="sm" mb="2">
            The following existing profiles match the name entered. Click &apos;Add&apos; if any of these profiles is
            the one you need:
          </Text>
          {profiles.map((profile) => {
            return (
              <Box key={profile.id} display="flex" alignItems="center" mb="2">
                <Button
                  size="sm"
                  variant="primary"
                  disabled={selectedProfiles.some((selectedProfile) => {
                    return selectedProfile.profile.id === profile.id;
                  })}
                  onClick={() => {
                    onAddExistingProfile(profile);
                  }}
                >
                  Add
                </Button>
                <Text ml="2">{profile.fullLegalName}</Text>
              </Box>
            );
          })}
        </>
      ) : (
        <>
          <Text mb="2">No matching profiles were found, instead:</Text>
          <Button
            onClick={() => {
              onDisplayCreateForm(true);
            }}
          >
            Create a new profile
          </Button>
        </>
      )}
    </Box>
  );
};

const validate = (values: any): any => {
  const errors: any = {};

  if (values.formMode === 'ADD_EXISTING_PROFILES') {
    if (values.selectedProfiles == null || values.selectedProfiles.length === 0) {
      errors.selectedProfiles = 'You should add at least one profile as related profile';
    } else {
      const selectedProfilesErrors: any[] = values.selectedProfiles.map(
        (selectedProfile: { relationships?: Array<{ value: string }> }) => {
          const spErrors: any = {};
          if (selectedProfile.relationships == null || selectedProfile.relationships.length === 0) {
            spErrors.relationships = 'Select at least one relation type';
          }
          return spErrors;
        }
      );

      const hasAnyError: boolean = selectedProfilesErrors.some((spError: object) => Object.keys(spError).length > 0);

      if (hasAnyError) {
        errors.selectedProfiles = selectedProfilesErrors;
      }
    }
  }
  return errors;
};

const AddExistingRelatedPartyForm: React.FC<AddExistingRelatedPartyFormProps> = ({
  searchValue,
  form,
  profileType,
  relationTypes,
  setDisplayCreateForm
}) => {
  const activeOrgID = useSelector(selectActiveOrgID);

  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }

  const [selectedProfiles, setSelectedProfiles] = useState<Array<{ profile: IProfile; relationships: any[] }>>([]);

  const handleAddExistingProfile = (profile): void => {
    const newSelected = [...selectedProfiles, { profile, relationships: relationTypes }];
    setSelectedProfiles(newSelected);
    form.change('selectedProfiles', newSelected);
  };

  const handleRemoveProfile = (profileId): void => {
    const newSelected = selectedProfiles.filter((p) => p.profile.id !== profileId);
    setSelectedProfiles(newSelected);
    form.change('selectedProfiles', newSelected);
  };

  const { data: profiles, isFetching } = useGetProfilesQuery(
    {
      orgId: activeOrgID,
      query: {
        page_size: 5,
        page: 1,
        search: searchValue,
        profile_type: profileType
      }
    },
    { skip: searchValue === '' }
  );

  return (
    <>
      {selectedProfiles.length > 0 && (
        <Box>
          {selectedProfiles.map((selectedProfile) => {
            return (
              <Box
                key={selectedProfile.profile.id}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb="1"
              >
                <Text flexBasis="100%">{selectedProfile.profile.fullLegalName}</Text>
                <Flex flexBasis="100%" ml="auto" alignItems="center" justifyContent="flex-end" gap="2">
                  <Box maxW="xs">
                    <StyledSelect
                      options={Object.entries(RELATION_TYPE_OPTIONS).map(([value, label]) => ({
                        value,
                        label
                      }))}
                      placeholder="Select relation types"
                      isMulti
                      size="sm"
                      value={selectedProfile.relationships}
                      onChange={(newValue: unknown) => {
                        const value = newValue as Array<{ value: string }>;
                        const newSelectedProfiles = selectedProfiles.map((tempSelectedProfile) => {
                          if (tempSelectedProfile.profile.id === selectedProfile.profile.id) {
                            return { ...tempSelectedProfile, relationships: value };
                          }
                          return tempSelectedProfile;
                        });
                        setSelectedProfiles(newSelectedProfiles);
                        form.change('selectedProfiles', newSelectedProfiles);
                      }}
                    />
                  </Box>

                  <IconButton
                    aria-label="Delete profile"
                    icon={<CloseIcon />}
                    size="xs"
                    colorScheme="gray"
                    onClick={() => {
                      handleRemoveProfile(selectedProfile.profile.id);
                    }}
                  />
                </Flex>
              </Box>
            );
          })}
        </Box>
      )}
      {searchValue !== '' && (
        <SearchResultsRelatedProfiles
          profiles={profiles?.results ?? []}
          selectedProfiles={selectedProfiles}
          onAddExistingProfile={handleAddExistingProfile}
          onDisplayCreateForm={(value) => {
            form.change('formMode', 'CREATE_NEW_PROFILE');
            setDisplayCreateForm(value);
          }}
          isFetching={isFetching}
          relationTypes={form.getFieldState('relationTypes')?.value ?? []}
        />
      )}
    </>
  );
};

export const AddPrimaryPartyForm: React.FC<ProfileFormProps> = ({
  profile,
  onSubmit,
  isRelatedParty = false,
  relationTypes = []
}) => {
  const toast = useToast();
  const [currentProfileType, setCurrentProfileType] = useState<PROFILE_TYPE>(
    profile?.resourcetype ?? PROFILE_TYPE.INDIVIDUAL
  );
  const [searchValue, setSearchValue] = useState('');

  const [displayCreateForm, setDisplayCreateForm] = useState(false);

  const handleSubmit = async (values): Promise<object | undefined> => {
    try {
      await onSubmit(values);
    } catch (error: unknown) {
      toast({
        title: 'Failed to save',
        description: 'Please try again.',
        status: 'error'
      });
      return convertObjectKeysToCamelCase((error as { data: object }).data);
    }
  };

  // We set the form id so we can submit the form externally
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        ...profile,
        relationTypes,
        profileType: currentProfileType,
        // Individual Profile
        citizenship: profile?.citizenship?.id,
        countryOfResidence: profile?.countryOfResidence?.id,
        idIssuer: profile?.idIssuer?.id,
        placeOfBirth: profile?.placeOfBirth?.id,
        gender: profile?.gender?.value,
        idType: profile?.idType?.value,
        // Entity Profile
        placeOfEstablishment: profile?.placeOfEstablishment?.id,
        // Extra
        formMode: isRelatedParty ? 'ADD_EXISTING_PROFILES' : 'CREATE_NEW_PROFILE'
      }}
      keepDirtyOnReinitialize
      validate={validate}
      render={({ handleSubmit, form, errors, submitFailed }) => {
        const relationTypesValue = form.getFieldState('relationTypes')?.value ?? [];

        return (
          <Box id="IndividualPrimaryParty" as="form" onSubmit={handleSubmit} w="100%">
            <Field name="selectedProfiles">{() => null}</Field>
            <Field name="formMode">{() => null}</Field>
            <VStack spacing={4} align="stretch" p={5}>
              {isRelatedParty && (
                <VStack spacing={4} align="stretch">
                  <FormField
                    name="relationTypes"
                    label="Relation Types"
                    validate={form.getFieldState('formMode')?.value === 'CREATE_NEW_PROFILE' ? ['required'] : []}
                  >
                    <StyledSelect
                      options={Object.entries(RELATION_TYPE_OPTIONS).map(([value, label]) => ({
                        value,
                        label
                      }))}
                      placeholder="Select relation types"
                      isMulti
                    />
                  </FormField>
                  <FormField name="profileType" label="Party Type" validate={['required']}>
                    {({ input }) => {
                      const options = [
                        { value: PROFILE_TYPE.INDIVIDUAL, label: 'Individual' },
                        { value: PROFILE_TYPE.ENTITY, label: 'Entity' }
                      ];

                      return (
                        <StyledSelect
                          {...input}
                          placeholder="Select profile type"
                          options={options}
                          isDisabled={profile?.resourcetype != null}
                          onChange={(newValue: unknown) => {
                            const value = newValue as { value: PROFILE_TYPE };
                            input.onChange(value);
                            setCurrentProfileType(value.value);
                          }}
                        />
                      );
                    }}
                  </FormField>
                  {!displayCreateForm && (
                    <FormField name="search" label="Search">
                      {({ input }) => {
                        return (
                          <Input
                            {...input}
                            onChange={(e) => {
                              input.onChange(e);
                              setSearchValue(e.target.value);
                            }}
                            placeholder="Enter full legal name"
                          />
                        );
                      }}
                    </FormField>
                  )}

                  <Divider />
                </VStack>
              )}
              {submitFailed && errors?.selectedProfiles != null && Array.isArray(errors.selectedProfiles) && (
                <Box mt={2}>
                  {errors.selectedProfiles.map((spError, index) => {
                    if (spError.relationships != null) {
                      return (
                        <Text key={index} color="red.500">
                          Profile #{index + 1}: {spError.relationships}
                        </Text>
                      );
                    }
                    return null;
                  })}
                </Box>
              )}
              {submitFailed && errors?.selectedProfiles != null && typeof errors.selectedProfiles === 'string' && (
                <Text color="red.500">{errors.selectedProfiles}</Text>
              )}
              {!displayCreateForm && isRelatedParty && (
                <AddExistingRelatedPartyForm
                  searchValue={searchValue}
                  setDisplayCreateForm={setDisplayCreateForm}
                  form={form}
                  profileType={currentProfileType}
                  relationTypes={relationTypesValue}
                />
              )}
              {(displayCreateForm || !isRelatedParty) && (
                <>
                  {currentProfileType === PROFILE_TYPE.INDIVIDUAL ? (
                    <IndividualPrimaryPartyForm form={form} />
                  ) : (
                    <EntityPrimaryPartyForm />
                  )}
                </>
              )}
            </VStack>
          </Box>
        );
      }}
    />
  );
};

interface ProfilePageProps {
  orgId: string;
  profile: IProfile;
}

const AddPrimaryPartyPage: React.FC<ProfilePageProps> = ({ orgId, profile }) => {
  const navigate = useNavigate();
  const { currentStep, maxSteps } = useProfileWizard();
  const { queryParams, setQueryParams } = useQueryParams();
  const { data: attachments } = useGetProfileAttachmentsQuery({ orgId, profileId: profile.id });
  const { isOpen, onOpen, onClose } = useDisclosure();

  const identificationAttachment = attachments?.find((attachment) => attachment.attachmentType === 'IDENTIFICATION');

  const { handleSubmit, isLoading } = useProfileForm({
    orgId,
    onSuccess: async () => {
      if (currentStep === maxSteps) {
        navigate(`/dashboard/profiles/${profile.id}`);
        return;
      }
      setQueryParams({ ...queryParams, step: currentStep + 1 });
    }
  });

  const onSubmit = async (values): Promise<void> => {
    await handleSubmit(values, profile.id);
  };

  return (
    <Box w="100%">
      {profile.resourcetype === PROFILE_TYPE.INDIVIDUAL && (
        <Flex justifyContent="flex-end" mb="4" px={4}>
          {identificationAttachment == null ? (
            <Button onClick={onOpen} leftIcon={<Icon icon="bx:scan" />} variant="secondary">
              Scan ID
            </Button>
          ) : (
            <Button
              bg="black"
              color="white"
              onClick={() => {
                window.open(identificationAttachment.file);
              }}
            >
              Attachment uploaded
            </Button>
          )}
        </Flex>
      )}
      <AddPrimaryPartyForm profile={profile} onSubmit={onSubmit} />
      {/* 
        Conditionally render modal despite having Chakra's useDisclosure hook.
        The hook only handles visibility while conditional rendering ensures the component fully unmounts,
        resetting its internal state on close.
      */}
      {isOpen && (
        <AttachmentModal
          isOpen={isOpen}
          onClose={onClose}
          orgId={orgId}
          profileId={profile.id}
          fixedAttachmentType="IDENTIFICATION"
        />
      )}
      <Flex justifyContent="flex-end" gap={4} mt={8} mb={6} px={4}>
        <Button
          onClick={() => {
            setQueryParams({ ...queryParams, step: currentStep - 1 });
          }}
          leftIcon={<Icon icon="ri:arrow-left-line" />}
          variant="secondaryLarge"
        >
          Back
        </Button>
        <Button
          type="submit"
          form="IndividualPrimaryParty"
          isLoading={isLoading}
          rightIcon={<Icon icon="ri:arrow-right-line" />}
          variant="primaryLarge"
        >
          {currentStep === maxSteps ? 'Finish' : 'Next'}
        </Button>
      </Flex>
    </Box>
  );
};

export default AddPrimaryPartyPage;

import { ChakraProvider, Container, useDisclosure } from '@chakra-ui/react';
import { Box, Paper, Checkbox } from '@mui/material';
import dayjs from 'dayjs';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import { MaterialReactTable, type MRT_ColumnDef, type MRT_RowData } from 'material-react-table';
import { useMemo, useState, type ForwardedRef, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import WalletModal from '@features/profile-wizard/components/common/AddWalletModal';
import { useCustomMaterialTable } from '@features/shared/hooks/useCustomMaterialTable';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { useGetWalletsQuery } from '@services/canaria.services';
import { chakraTheme, RISK_BG_COLORS } from '@utils/consts';

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: ['id', 'risk_level', 'name', 'address', 'created_at', 'recent_screening', 'cadence', 'notes']
};

const minifyAddress = (address: string): string => {
  if (address.length > 20) {
    return `${address.slice(0, 8)}...${address.slice(-8)}`;
  }
  return address;
};

interface WalletRow extends MRT_RowData {
  id: string;
  name: string;
  address: string;
  created_at: string;
  last_inquiry?: {
    created_at: string;
    result: {
      risk: string;
    };
  };
  schedule?: {
    frequency: string;
  };
}

export interface WalletsPanelRef {
  openWalletModal: () => void;
  exportSelectedRows: () => void;
}

interface WalletsPanelProps {
  profileId?: string;
}

const WalletsPanel = forwardRef(({ profileId }: WalletsPanelProps, ref: ForwardedRef<WalletsPanelRef>) => {
  const activeOrgID = useSelector(selectActiveOrgID);
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }

  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<Array<{ id: string; desc: boolean }>>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25
  });

  const { data, isFetching, isLoading } = useGetWalletsQuery({
    orgId: activeOrgID,
    query: {
      page_size: pagination.pageSize,
      page: pagination.pageIndex + 1,
      ordering: sorting
        .map((s) => `${s.desc ? '-' : ''}${s.id}`)
        .join(',')
        .replaceAll('.', '__'),
      search: globalFilter,
      ...(profileId != null && { profiles: profileId })
    }
  });

  const columns = useMemo<Array<MRT_ColumnDef<WalletRow>>>(
    () => [
      {
        id: 'select',
        header: 'Select All',
        Header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        Cell: ({ row }: any) => (
          <Checkbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} sx={{ padding: '0px' }} />
        ),
        enableColumnFilter: false,
        enableSorting: false,
        size: 1
      },
      {
        header: 'ID',
        accessorKey: 'id',
        size: 4,
        Cell: ({ cell }: any) => (
          <Link
            to={{ pathname: `/dashboard/wallets/${cell.getValue()}` }}
            state={{
              profileId
            }}
          >
            {cell.getValue()}
          </Link>
        )
      },
      {
        header: 'Risk Level',
        accessorFn: (row) => row?.last_inquiry?.result?.risk,
        size: 5,
        Cell: ({ cell }: any) => {
          const risk = cell.getValue() ?? '';
          return (
            <Box
              sx={() => ({
                backgroundColor: RISK_BG_COLORS[risk.toUpperCase()] ?? 'white',
                borderRadius: '0.25rem',
                color: '#fff',
                width: 'fit-content',
                px: '6px',
                lineHeight: 1,
                height: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              })}
            >
              {risk.toUpperCase()}
            </Box>
          );
        },
        filterVariant: 'select' as 'select', // FIX THIS WORKAROUND
        filterSelectOptions: [
          { text: 'Low', value: 1 },
          { text: 'Medium', value: 2 },
          { text: 'High', value: 3 },
          { text: 'Severe', value: 4 }
        ]
      },
      {
        header: 'Name',
        accessorKey: 'name',
        size: 6,
        Cell: ({ cell }: any) => (
          <Link to={{ pathname: `/dashboard/wallets/${cell.row.original.id}` }}>{cell.getValue()}</Link>
        )
      },
      {
        header: 'Address',
        accessorKey: 'address',
        size: 6,
        Cell: ({ cell }: any) => (
          <Link to={{ pathname: `/dashboard/wallets/${cell.row.original.id}` }}>{minifyAddress(cell.getValue())}</Link>
        )
      },
      {
        header: 'Blockchain',
        accessorKey: 'blockchain',
        size: 6,
        Cell: ({ cell }: any) => (
          <Link to={{ pathname: `/dashboard/wallets/${cell.row.original.id}` }}>{minifyAddress(cell.getValue())}</Link>
        )
      },
      {
        header: 'Initial Screening',
        accessorKey: 'created_at',
        Cell: ({ cell: { getValue } }) => {
          const value = getValue();
          if (value == null) return null;
          return dayjs(value as string).format('YYYY-MM-DD hh:mma');
        },
        size: 6,
        enableColumnFilter: false
      },
      {
        header: 'Recent Screening',
        accessorKey: 'last_inquiry__created_at',
        accessorFn: (row) => row?.last_inquiry?.created_at,
        Cell: ({ cell: { getValue } }) => {
          const value = getValue();
          if (value == null) return null;
          return dayjs(value as string).format('YYYY-MM-DD hh:mma');
        },
        enableColumnFilter: false
      },
      {
        header: 'Cadence',
        accessorKey: 'schedule.frequency',
        accessorFn: (row) => (row?.schedule != null ? row.schedule.frequency : null),
        enableColumnFilter: false,
        size: 1
      }
    ],
    [profileId]
  );

  const { table } = useCustomMaterialTable<WalletRow>({
    columns,
    data: data?.results ?? [],
    rowCount: data?.count ?? 0,
    isLoading,
    isFetching,
    pagination,
    setPagination,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    enableRowSelection: true,
    enableMultiRowSelection: true
  });

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportRows = (rows: any[]): void => {
    if (rows.length === 0) {
      return;
    }

    csvExporter.generateCsv(
      rows.map((row) => {
        const r = row.original;
        return {
          id: r.id,
          risk_level: r?.last_inquiry?.result?.risk ?? '',
          name: r.name,
          address: r.address,
          created_at: r.created_at,
          recent_screening: r?.last_inquiry?.created_at ?? '',
          cadence: r?.schedule?.frequency ?? '',
          notes: r.notes ?? ''
        };
      })
    );
  };

  useImperativeHandle(ref, () => ({
    openWalletModal: onOpen,
    exportSelectedRows: () => {
      const selectedRows = table.getSelectedRowModel().rows;
      if (selectedRows.length === 0) {
        return;
      }
      handleExportRows(selectedRows);
    }
  }));

  return (
    <Container maxW="full">
      <ChakraProvider theme={chakraTheme}>
        <WalletModal isOpen={isOpen} onClose={onClose} orgId={activeOrgID} profileId={profileId} />
      </ChakraProvider>

      <Paper
        elevation={0}
        style={{
          maxWidth: '100%',
          overflowX: 'auto',
          borderRadius: '12px'
        }}
      >
        <MaterialReactTable table={table} />
      </Paper>
    </Container>
  );
});

WalletsPanel.displayName = 'WalletsPanel';

export default WalletsPanel;

import { Button, IconButton, Flex, Heading, VStack, Box, useToast } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import EditableField from '@features/profile-overview/components/EditableField/';
import EditableAddress from '@features/profile-overview/components/EditableField/EditableAddress';
import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { PROFILE_TYPE, type IProfile } from '@models/profileTypes';
import {
  useGetGendersQuery,
  useGetCountriesQuery,
  useGetEntityTypesQuery,
  useGetNatureOfEmploymentQuery,
  useGetIdTypesQuery,
  useUpdateProfileMutation,
  useNewProfileInquiryMutation
} from '@services/canaria.services';

const MotionVStack = motion.create(VStack as any);

export interface PrimaryPartyInformationRef {
  expand: () => void;
  scrollIntoView: (options?: ScrollIntoViewOptions) => void;
}

const PrimaryPartyInformationEntity: React.FC<{ profile: IProfile }> = ({ profile }) => {
  const activeOrgID = useSelector(selectActiveOrgID);

  if (activeOrgID == null) {
    throw new Error('activeOrgID is null');
  }

  const [updateProfile] = useUpdateProfileMutation();
  const { data: countries = [] } = useGetCountriesQuery(null);
  const { data: entityTypes = [] } = useGetEntityTypesQuery(null);
  const mappedCountries = countries.map((country) => ({
    value: country.id,
    name: country.englishName
  }));

  const handleFieldChange = (name: string) => async (value) => {
    await updateProfile({
      orgId: activeOrgID,
      profileId: profile.id,
      [name]: value
    }).unwrap();
  };

  return (
    <MotionVStack
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: 'auto' }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 0.3 }}
      spacing={3}
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      width="100%"
    >
      <EditableField
        label="Full Legal Name"
        value={profile?.fullLegalName}
        onConfirmChange={handleFieldChange('fullLegalName')}
        type="text"
      />
      <EditableField
        label="DBA/Trade Name"
        value={profile?.dbaTradeName}
        onConfirmChange={handleFieldChange('dbaTradeName')}
        type="text"
      />
      <EditableField label="Email" value={profile?.email} onConfirmChange={handleFieldChange('email')} type="text" />
      <EditableField
        label="Entity Formation Type"
        value={profile?.entityFormationType}
        type="select"
        onConfirmChange={handleFieldChange('entityFormationType')}
        options={entityTypes}
      />
      <EditableField
        label="Place of Establishment"
        value={profile?.placeOfEstablishment?.id}
        onConfirmChange={handleFieldChange('placeOfEstablishment')}
        type="select"
        options={mappedCountries}
      />
      <EditableField
        label="Date of Establishment"
        value={profile?.dateOfEstablishment}
        onConfirmChange={handleFieldChange('dateOfEstablishment')}
        type="date"
      />
      <EditableField
        label="Unique Identification Number"
        value={profile?.uniqueIdentificationNumber}
        onConfirmChange={handleFieldChange('uniqueIdentificationNumber')}
        type="text"
      />
      <EditableAddress
        label="Registered Business Address"
        value={profile?.registeredBusinessAddress}
        onConfirmChange={handleFieldChange('registeredBusinessAddress')}
      />
      <EditableAddress
        label="Mailing Address"
        value={profile?.mailingAddress}
        onConfirmChange={handleFieldChange('mailingAddress')}
      />
      <EditableAddress
        label="Physical Address"
        value={profile?.physicalAddress}
        onConfirmChange={handleFieldChange('physicalAddress')}
      />
      <EditableField
        label="Company website"
        value={profile?.companyWebsite}
        onConfirmChange={handleFieldChange('companyWebsite')}
        type="text"
      />
      <EditableField
        label="Point of Contact Name"
        value={profile?.pointOfContactName}
        onConfirmChange={handleFieldChange('pointOfContactName')}
        type="text"
      />
      <EditableField
        label="Ownership Percentage"
        value={profile?.ownershipPercentage}
        onConfirmChange={handleFieldChange('ownershipPercentage')}
        type="text"
      />
    </MotionVStack>
  );
};

interface PrimaryPartyInformationProps {
  handleSectionHover: (section: string) => void;
  handleFieldChange: (field: string) => (value: string | number | Date | Array<string | number>) => Promise<any>;
  profile: IProfile;
  mappedCountries: any[];
  profileId: string;
}

const PrimaryPartyInformation = forwardRef<PrimaryPartyInformationRef, PrimaryPartyInformationProps>(
  ({ profile, handleSectionHover, handleFieldChange, mappedCountries, profileId }, ref) => {
    const activeOrgID = useSelector(selectActiveOrgID);
    const [isOpen, setIsOpen] = useState(true);
    const containerRef = useRef<HTMLDivElement>(null);
    const { data: genders = [] } = useGetGendersQuery({});
    const { data: idTypes = [] } = useGetIdTypesQuery({});
    const { data: natureOfEmployment = [] } = useGetNatureOfEmploymentQuery({});
    const [newProfileInquiry, { isLoading }] = useNewProfileInquiryMutation();
    const toast = useToast();

    useImperativeHandle(ref, () => ({
      expand: () => {
        setIsOpen(true);
      },
      scrollIntoView: (options?: ScrollIntoViewOptions) => {
        containerRef.current?.scrollIntoView(options);
      }
    }));

    return (
      <VStack
        ref={containerRef}
        spacing={3}
        align="stretch"
        m={3}
        p={3}
        boxShadow="md"
        bg="white"
        w="100%"
        layerStyle="container"
        onMouseEnter={() => {
          handleSectionHover('Primary party information');
        }}
      >
        <Flex justifyContent="space-between" width="full" alignItems="center">
          <Heading as="h1" size="md" textAlign="left">
            Primary Party Information
          </Heading>
          <Flex alignItems="center">
            <Button
              size="sm"
              color="#2081C3"
              bg="transparent"
              opacity={isLoading ? 0.7 : 1}
              isDisabled={isLoading}
              onClick={async () => {
                try {
                  await newProfileInquiry({
                    orgId: activeOrgID ?? '',
                    profileId
                  }).unwrap();
                  toast({
                    title: 'Profile rescreening complete',
                    status: 'success',
                    duration: 3000,
                    isClosable: true
                  });
                } catch (error) {
                  toast({
                    title: 'Error rescreening profile',
                    description: 'Please try again later',
                    status: 'error',
                    duration: 5000,
                    isClosable: true
                  });
                }
              }}
            >
              <Icon icon="bx:refresh" className={isLoading ? 'icon-spin' : ''} />
              Rescreen
            </Button>
            <Link to={`/dashboard/profiles/${profileId}/screenings`}>
              <Box position="relative">
                <Button size="sm" color="#2081C3" bg="transparent">
                  <Icon icon="bx:search" />
                  Screening hits
                </Button>
                {profile.totalUnresolvedHits > 0 && (
                  <Box
                    position="absolute"
                    top="-4px"
                    right="2px"
                    bg="#D72638"
                    color="white"
                    borderRadius="full"
                    w="16px"
                    h="16px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="10px"
                    fontWeight="bold"
                  >
                    {profile.totalUnresolvedHits}
                  </Box>
                )}
              </Box>
            </Link>
            <IconButton
              aria-label="Toggle Primary Party Information"
              icon={isOpen ? <Icon icon="fa6-solid:chevron-up" /> : <Icon icon="fa6-solid:chevron-down" />}
              size="sm"
              variant="ghost"
              onClick={() => {
                setIsOpen((prev) => !prev);
              }}
            />
          </Flex>
        </Flex>
        <AnimatePresence>
          {isOpen && (
            <MotionVStack
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              spacing={3}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
            >
              {profile?.resourcetype === PROFILE_TYPE.INDIVIDUAL ? (
                <>
                  <EditableField
                    label="Full legal name"
                    value={profile?.fullLegalName}
                    onConfirmChange={handleFieldChange('fullLegalName')}
                    type="text"
                  />
                  <EditableField
                    label="Email"
                    value={profile?.email}
                    onConfirmChange={handleFieldChange('email')}
                    type="text"
                  />
                  <EditableField
                    label="Gender"
                    value={profile?.gender?.value}
                    onConfirmChange={handleFieldChange('gender')}
                    type="select"
                    options={genders}
                  />
                  <EditableField
                    label="Date of birth"
                    value={profile?.dateOfBirth}
                    onConfirmChange={handleFieldChange('dateOfBirth')}
                    type="date"
                  />
                  <EditableField
                    label="Citizenship"
                    value={profile?.citizenship?.id}
                    onConfirmChange={handleFieldChange('citizenship')}
                    type="select"
                    options={mappedCountries}
                  />
                  <EditableField
                    label="Country of Residence"
                    value={profile?.countryOfResidence?.id}
                    onConfirmChange={handleFieldChange('countryOfResidence')}
                    type="select"
                    options={mappedCountries}
                  />
                  <EditableField
                    label="State"
                    value={profile?.state}
                    onConfirmChange={handleFieldChange('state')}
                    type="text"
                  />
                  <EditableField
                    label="City"
                    value={profile?.city}
                    onConfirmChange={handleFieldChange('city')}
                    type="text"
                  />

                  <EditableField
                    label="Postal code"
                    value={profile?.postalCode}
                    onConfirmChange={handleFieldChange('postalCode')}
                    type="text"
                  />
                  <EditableAddress
                    label="Address"
                    value={profile?.address}
                    onConfirmChange={handleFieldChange('address')}
                  />
                  <EditableField
                    label="Place of Birth"
                    value={profile?.placeOfBirth?.id}
                    onConfirmChange={handleFieldChange('placeOfBirth')}
                    type="select"
                    options={mappedCountries}
                  />
                  <EditableField
                    label="ID Issuer"
                    value={profile?.idIssuer?.id}
                    onConfirmChange={handleFieldChange('idIssuer')}
                    type="select"
                    options={mappedCountries}
                  />
                  <EditableField
                    label="ID type"
                    value={profile?.idType?.value}
                    onConfirmChange={handleFieldChange('idType')}
                    type="select"
                    options={idTypes}
                  />
                  <EditableField
                    label="ID number"
                    value={profile?.idNumber}
                    onConfirmChange={handleFieldChange('idNumber')}
                    type="text"
                  />
                  <EditableField
                    label="Nature of employment"
                    value={profile?.natureOfEmployment}
                    onConfirmChange={handleFieldChange('natureOfEmployment')}
                    type="select"
                    options={natureOfEmployment}
                  />
                  <EditableField
                    label="Control person title"
                    value={profile?.controlPersonTitle}
                    onConfirmChange={handleFieldChange('controlPersonTitle')}
                    type="text"
                  />
                  <EditableField
                    label="Ownership percentage"
                    value={profile?.ownershipPercentage}
                    onConfirmChange={handleFieldChange('ownershipPercentage')}
                    type="text"
                  />
                </>
              ) : (
                <PrimaryPartyInformationEntity profile={profile} />
              )}
            </MotionVStack>
          )}
        </AnimatePresence>
      </VStack>
    );
  }
);

PrimaryPartyInformation.displayName = 'PrimaryPartyInformation';

export default PrimaryPartyInformation;

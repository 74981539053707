import {
  Box,
  Flex,
  Text,
  VStack,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Button,
  Textarea,
  useToast,
  ChakraProvider
} from '@chakra-ui/react';
import { useState } from 'react';

import { mapScreeningHit } from '@features/panels/alertsPanel/utils/screeningHit';
import type { ScreeningHit } from '@models/alert';
import type { MappedScreeningHitType } from '@features/panels/alertsPanel/utils/screeningHitTypes';
import { chakraTheme } from '@utils/consts';
import { schemaFieldMappings } from '@features/panels/alertsPanel/utils/screeningHitFields';

const LineField: React.FC<{
  label: string;
  value: string | undefined | null | string[];
  hideIfEmpty?: boolean;
  isLast?: boolean;
  clickable?: boolean;
}> = ({ label, value, hideIfEmpty = false, isLast = false, clickable = false }): JSX.Element | null => {
  if (value === null || value === undefined) {
    return null;
  }
  if (hideIfEmpty && value === '') {
    return null;
  }
  return (
    <Box flex="1 0 21%" borderRight={!isLast ? '1px solid' : 'none'} borderColor="gray.200" px={4}>
      <Flex direction="column" justify="flex-start" height="100%">
        <Text fontSize="xs" color="gray.500" mb={1}>
          {label}
        </Text>
        {
          clickable && (
            // if it is clickable, it means value is a list of strings and we need to render each one as a link
            Array.isArray(value) && value.map((item, index) => (
              <Text key={index} flex="1" whiteSpace="normal" wordBreak="break-word" textAlign="left" color="blue.500">
                <a href={item} target="_blank" rel="noopener noreferrer" key={index}>
                  {item}
                </a>
              </Text>
            ))
          )
        }

        {
          !clickable && (
            <Text pb={1} minHeight="3em" whiteSpace="normal" wordBreak="break-word">
              {value}
            </Text>
          )
        }
      </Flex>
    </Box>
  );
};

const ListField: React.FC<{
  label: string;
  items: Array<string | undefined>;
  hideIfEmpty?: boolean;
  isHorizontal?: boolean;
  clickable?: boolean;
}> = ({ label, items, hideIfEmpty = false, isHorizontal = false, clickable = false }): JSX.Element | null => {
  if (hideIfEmpty && items.length === 0) {
    return null;
  }

  if (isHorizontal) {
    const validItems = items.filter((item): item is string => item !== undefined);
    return (
      <Box width="100%">
        <Flex direction="row" justify="space-between" width="100%" gap={4}>
          <Text color="black" fontSize="md" flex="0 0 auto">
            {label}:
          </Text>
          <Text flex="1" whiteSpace="normal" wordBreak="break-word" textAlign="right" maxWidth="50%">
            {validItems.join(', ')}
          </Text>
        </Flex>
      </Box>
    );
  }

  return (
    <Box width="auto">
      {items.map(
        (item, index) =>
          item !== undefined && (
            <Flex key={index} direction="row" justify="flex-start" gap={2}>
              {index === 0 && (
                <Text color="black" fontSize="md" flex="0 0 auto">
                  {label}:
                </Text>
              )}
              <Text flex="1" whiteSpace="normal" wordBreak="break-word" textAlign="right" color="blue.500">
                {clickable ? (
                  <a href={item} target="_blank" rel="noopener noreferrer">
                    {item}
                  </a>
                ) : (
                  item
                )}
              </Text>
            </Flex>
          )
      )}
    </Box>
  );

};


const MatchingScreeningHit = ({
  screeningHit,
  onResolve
}: {
  screeningHit: ScreeningHit;
  onResolve: (id: number, resolution: string, notes: string) => void;
}): JSX.Element => {
  const mappedHit = mapScreeningHit(screeningHit);
  
  const toast = useToast();
  const [resolution, setResolution] = useState<'TRUE_MATCH' | 'FALSE_POSITIVE' | 'NO_RESOLUTION'>(
    screeningHit.resolution as 'TRUE_MATCH' | 'FALSE_POSITIVE' | 'NO_RESOLUTION'
  );
  const [notes, setNotes] = useState(screeningHit.notes);
  const isResolved = screeningHit.resolution !== 'NO_RESOLUTION';
  const [isEditing, setIsEditing] = useState(false);

  interface AdditionalFieldsListProps {
    additionalFields: MappedScreeningHitType['additionalFields'];
    fields: Array<{
      fieldName: keyof MappedScreeningHitType['additionalFields'];
      label: string;
    }>;
  }

  const AdditionalFieldsList: React.FC<AdditionalFieldsListProps> = ({ additionalFields, fields }) => (
    <VStack align="stretch" spacing={4} divider={<Box borderBottom="1px solid" borderColor="gray.200" />}>
      {fields.map(({ label, fieldName }) => {
        const items = additionalFields[fieldName];
        if (!items || items.length === 0) return null;
        return (
          <ListField
            key={fieldName}
            label={label}
            items={Array.isArray(items) ? items : [items]}
            isHorizontal
          />
        );
      })}
      {additionalFields.website && additionalFields.website?.length > 0 && (
        <ListField label="Websites" items={additionalFields.website} clickable />
      )}
    </VStack>
  );
  const fieldsPerEntityType = (
      { entityType, excludedFields }:
      { entityType: string, excludedFields?: string[]
    }): { label: string, fieldName: string }[] => {

    const selectedFields = schemaFieldMappings[entityType];
    if (!selectedFields) return [];

    if (excludedFields && excludedFields.length > 0) {
      excludedFields.forEach((fieldName) => {
        delete selectedFields[fieldName];
      });
    }

    return Object.entries(selectedFields).map(([fieldName, label]) => ({
      fieldName,
      label,
    }));
  }

  return (
    <ChakraProvider theme={chakraTheme}>
      <Flex p={4} bg="transparent">
        <VStack align="stretch" flex={1} spacing={4}>
          <VStack align="stretch" spacing={4} width="100%" layerStyle="container" bg="white">
            <LineField key="entityType" label="Entity Type" value={mappedHit.entityType} />
            <Box borderBottom="1px solid" borderColor="gray.200" width="100%" />
            <LineField key="entityName" label="Entity Name" value={mappedHit.additionalFields.name?.[0]} />
            {mappedHit.additionalFields.sourceUrl && mappedHit.additionalFields.sourceUrl.length > 0 && (
              <>
                <Box borderBottom="1px solid" borderColor="gray.200" width="100%" />
                <LineField key="sourceUrl" label="Source URLs" value={mappedHit.additionalFields.sourceUrl} clickable />
              </>
            )
            }
          </VStack>

          <VStack align="stretch" spacing={4} width="100%" layerStyle="container" bg="white">
            <AdditionalFieldsList
              additionalFields={mappedHit.additionalFields}
              fields={fieldsPerEntityType({
                entityType: mappedHit.entityType,
                /**
                // Use excludedFields to exclude fields from the list
                // Take into account that some fields are pre-defined by default
                // see screeningHitFields.ts for more details
                // this might be used to include only required fields
                // in the future
                **/
                excludedFields: ['sourceUrl', 'notes', 'website'] 
                }) as Array<{ fieldName: keyof typeof mappedHit.additionalFields, label: string }>
            }
            />
          </VStack>

          {mappedHit.additionalFields.notes && mappedHit.additionalFields.notes?.length > 0 && (
          <VStack align="stretch" spacing={4} width="100%" layerStyle="container" bg="white">
            <VStack align="stretch" spacing={4} divider={<Box borderBottom="1px solid" borderColor="gray.200" />}>
              <ListField label="Notes" items={mappedHit.additionalFields.notes} isHorizontal/>
            </VStack>
          </VStack>
          )}

          <VStack align="stretch" spacing={4} width="100%" layerStyle="container" bg="white">
            <VStack align="stretch" spacing={4}>
              <Text>Resolution Comments</Text>
              <Textarea
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                placeholder="Enter notes here"
                size="sm"
                isDisabled={isResolved && !isEditing}
                minHeight="100px"
                borderRadius="xl"
                p={4}
              />
              <RadioGroup
                onChange={(value) => {
                  setResolution(value as 'TRUE_MATCH' | 'FALSE_POSITIVE');
                }}
                value={resolution}
                isDisabled={isResolved && !isEditing}
              >
                <Stack direction="row" divider={<Box borderLeft="1px solid" borderColor="gray.200" mx={4} />}>
                  <Radio value="TRUE_MATCH">True Match</Radio>
                  <Radio value="FALSE_POSITIVE">False Positive</Radio>
                </Stack>
              </RadioGroup>

              <Flex mt={2} gap={2} width="100%">
                {isEditing && (
                  <Button
                    size="md"
                    borderRadius="xl"
                    py={2}
                    width="100%"
                    onClick={() => {
                      setIsEditing(false);
                    }}
                    fontWeight={600}
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  size="md"
                  borderRadius="xl"
                  py={2}
                  width="100%"
                  onClick={() => {
                    if (isResolved && !isEditing) {
                      setIsEditing(true);
                      return;
                    }
                    if (resolution === 'NO_RESOLUTION' || notes.trim() === '') {
                      toast({
                        status: 'error',
                        title: 'Error',
                        description: 'Please select a resolution and add notes to resolve the match!',
                        isClosable: true
                      });
                      return;
                    }
                    onResolve(screeningHit.id, resolution, notes);
                  }}
                  fontWeight={600}
                  variant="primary"
                >
                  {isResolved && !isEditing ? 'Edit Resolution' : 'Resolve Match'}
                </Button>
              </Flex>
              {isResolved && !isEditing && (
                <Text fontSize="sm" color="gray.500" mt={2}>
                  This match has been resolved
                </Text>
              )}
            </VStack>
          </VStack>
        </VStack>
      </Flex>
    </ChakraProvider>
  );
};

export default MatchingScreeningHit;
